import { withStyles, WithStyles } from '@mui/styles';
import {
  chartHeight,
  chartInnerRadius,
  chartOuterRadius,
  chartWidth,
  countUpTime,
  DeviceIntegrationStyles,
  fadeTimeout,
  saturation,
} from '../Styles/DeviceIntegrationStyles';
import { ServiceStatusStatistic } from '@ekkogmbh/apisdk';
import React, { Component } from 'react';
import { Card, CardContent, Color, colors, Grow, Typography } from '@mui/material';
import { PieChart } from 'recharts';
import { Pie } from 'recharts';
import { Cell } from 'recharts';
import CountUp from 'react-countup';

const styles = DeviceIntegrationStyles;

export interface StatisticElementProps extends WithStyles<typeof styles> {
  statistic: ServiceStatusStatistic;
}

interface StatisticElementState {}

class StatisticElementComponent extends Component<StatisticElementProps, StatisticElementState> {
  public state: StatisticElementState = {};

  public getColor(input: string): Color {
    switch (input) {
      case 'offline':
      case 'bad':
      case 'disconnected':
        return colors.red;
      case 'online':
      case 'good':
      case 'succesful': // anticipating typo
      case 'successful':
      case 'connected':
        return colors.green;
      case 'excellent':
        return colors.lightGreen;
      case 'unknown':
        return colors.grey;
      default:
        return colors.orange;
    }
  }

  public makePie(): JSX.Element {
    const { statistic, classes } = this.props;

    const data = Object.keys(statistic.data).map((name) => ({
      name,
      value: statistic.data[name],
      color: this.getColor(name),
    }));

    return (
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardTitle} color={'textPrimary'} gutterBottom>
            {statistic.name}
          </Typography>

          <PieChart width={chartWidth} height={chartHeight} className={classes.chart}>
            <Pie
              data={data}
              innerRadius={chartInnerRadius}
              outerRadius={chartOuterRadius}
              paddingAngle={0}
              dataKey={'value'}
            >
              {data.map(({ color }, index: number) => (
                <Cell key={index} fill={color[saturation]} />
              ))}
            </Pie>
          </PieChart>

          <Typography component={'div'} className={classes.cardText} color={'textPrimary'}>
            {data.map(({ color, value }, index: number) => (
              <React.Fragment key={index}>
                <span style={{ color: color[saturation] }}>
                  <CountUp start={0} end={value} duration={countUpTime / 1000} delay={fadeTimeout / 1000} />
                </span>
                {index === data.length - 1 ? '' : <span>&nbsp;/&nbsp;</span>}
              </React.Fragment>
            ))}
          </Typography>
          <Typography className={classes.cardCaption} color={'textPrimary'}>
            {data.map(({ name }, index: number) => (
              <React.Fragment key={index}>
                <span>{name}</span>
                {index === data.length - 1 ? '' : <span>&nbsp;/&nbsp;</span>}
              </React.Fragment>
            ))}
          </Typography>
        </CardContent>
      </Card>
    );
  }

  public render() {
    return (
      <Grow in={true} timeout={fadeTimeout}>
        {this.makePie()}
      </Grow>
    );
  }
}

const StyleWrapped = withStyles(styles)(StatisticElementComponent);
export const StatisticElement = StyleWrapped;
