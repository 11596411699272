import { ArrowBack, ArrowForward, Cancel, Delete, RestartAlt, Save } from '@mui/icons-material';
import { Grid, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { FormStyles } from '../Styles/FormStyles';

export enum PanelAction {
  CREATE = 'Save',
  EDIT = 'Edit',
}

// @TODO refactor?
interface FormPanelButtonsProps extends WithStyles<typeof FormStyles> {
  cancelHandler: () => void;
  finished?: boolean;
  deleteHandler?: () => void;
  resetHandler?: () => void;
  backHandler?: () => void;
  nextHandler?: () => void;
  saveHandler?: () => void;
  isDeleteDisabled?: boolean;
  isResetDisabled?: boolean;
  isBackDisabled?: boolean;
  isNextDisabled?: boolean;
  isSaveDisabled?: boolean;
  isDeleteHidden?: boolean;
  isNextHidden?: boolean;
}

class FormPanelButtonsComponent extends React.PureComponent<FormPanelButtonsProps> {
  public render() {
    const {
      classes,
      cancelHandler,
      finished,
      deleteHandler,
      resetHandler,
      backHandler,
      nextHandler,
      saveHandler,
      isDeleteDisabled,
      isResetDisabled,
      isNextDisabled,
      isBackDisabled,
      isSaveDisabled,
      isDeleteHidden,
    } = this.props;

    return (
      <Grid item xs={12} sx={{ mt: 3 }}>
        <Stack direction="row" spacing={1} sx={{ justifyContent: 'space-between' }}>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={cancelHandler}
              startIcon={<Cancel />}
            >
              {finished ? 'Close' : 'Cancel'}
            </Button>

            {resetHandler !== undefined && (
              <Button
                variant="outlined"
                color="secondary"
                className={classes.button}
                disabled={isResetDisabled}
                onClick={resetHandler}
                style={{ visibility: finished ? 'hidden' : 'visible' }}
                startIcon={<RestartAlt />}
              >
                Reset
              </Button>
            )}
          </div>

          <div>
            {backHandler !== undefined && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={isBackDisabled}
                onClick={backHandler}
                style={{ visibility: finished ? 'hidden' : 'visible' }}
                startIcon={<ArrowBack />}
              >
                Back
              </Button>
            )}

            {nextHandler !== undefined && (
              <Button
                variant="contained"
                color="secondary"
                className={classes.button}
                disabled={isNextDisabled}
                onClick={nextHandler}
                style={{ visibility: finished ? 'hidden' : 'visible' }}
                endIcon={<ArrowForward />}
              >
                Next
              </Button>
            )}
          </div>

          <div>
            <Button
              variant="outlined"
              color="secondary"
              className={classes.button}
              disabled={isDeleteDisabled}
              onClick={deleteHandler}
              style={{ visibility: isDeleteHidden || finished ? 'hidden' : 'visible' }}
              startIcon={<Delete />}
            >
              Delete
            </Button>

            {saveHandler !== undefined && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={isSaveDisabled}
                onClick={saveHandler}
                style={{ visibility: finished ? 'hidden' : 'visible' }}
                endIcon={<Save />}
              >
                Save
              </Button>
            )}
          </div>
        </Stack>
      </Grid>
    );
  }
}

export const FormPanelButtons = withStyles(FormStyles)(FormPanelButtonsComponent);
