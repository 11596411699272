import {
  ConfirmationPattern,
  DeviceTrigger,
  EventType,
  NotificationStrategy,
  Zone,
  ZonePayload,
} from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';
import { invalidMergeConfig } from '../Helper/Strategies';

export type ZoneState = ZonePayload;

const defaultZoneState = (): ZoneState => ({
  name: '',
  coordinate: '',
  sourcePoolIdentifier: {
    name: '',
    coordinate: '',
  },
  destinationPoolIdentifier: {
    name: '',
    coordinate: '',
  },
  confirmationStrategy: {
    name: 'strict',
    configuration: {},
  },
  notificationStrategy: {
    name: NotificationStrategy.NULL,
    configuration: {},
  },
  mergeStrategy: {
    name: 'append',
    configuration: {},
  },
  sortSteps: 'no',
  maxParallelOpenOrders: 0,
  sourceConfirmationPattern: ConfirmationPattern.DEFAULT,
  sourceOptionalConfirmation: false,
  destinationConfirmationPattern: ConfirmationPattern.DEFAULT,
  destinationOptionalConfirmation: false,
  autoConclude: false,
  autoDelete: false,
  sourceValueExtenders: undefined,
  destinationValueExtenders: undefined,
  buttonConfirmationEventTopic: '',
  confirmationTrigger: {
    type: EventType.DEVICE_TRIGGER,
    properties: {
      deviceTriggerType: 'button-pressed',
    },
  } as DeviceTrigger,
});

export class ZoneStore {
  @observable
  public editableZone?: Zone;

  @observable
  public isSaveable: boolean = false;

  @observable
  public state: ZoneState = {
    ...defaultZoneState(),
  };

  @action
  public setState = (newState: Partial<ZoneState>): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    this.isSaveable =
      this.state.name !== '' && this.state.coordinate !== '' && !invalidMergeConfig(this.state.mergeStrategy);
  };

  @action setEditableZone = (zone: Zone): void => {
    this.editableZone = zone;
    this.state = this.convertZoneToZoneState(zone);
    this.isSaveable = true;
  };

  @action
  public resetStore = (): void => {
    this.editableZone = undefined;
    this.setState({
      ...defaultZoneState(),
    });
  };

  @action
  public reset = (): void => {
    if (this.editableZone !== undefined) {
      this.setState(this.convertZoneToZoneState(this.editableZone));
    } else {
      this.resetStore();
    }
  };

  public getSavableZone(): ZonePayload {
    const zonePayload = { ...this.state };

    if (zonePayload.sourcePoolIdentifier && zonePayload.sourcePoolIdentifier.name === '') {
      delete zonePayload.sourcePoolIdentifier;
    }

    if (zonePayload.destinationPoolIdentifier && zonePayload.destinationPoolIdentifier.name === '') {
      delete zonePayload.destinationPoolIdentifier;
    }

    if (zonePayload.buttonConfirmationEventTopic === '') {
      delete zonePayload.buttonConfirmationEventTopic;
    }

    return zonePayload;
  }

  private convertZoneToZoneState(zone: Zone): ZoneState {
    return {
      name: zone.name,
      coordinate: zone.coordinate,
      sourcePoolIdentifier: zone.sourcePool
        ? {
            name: zone.sourcePool.name,
            coordinate: zone.sourcePool.coordinate,
          }
        : undefined,
      destinationPoolIdentifier: zone.destinationPool
        ? {
            name: zone.destinationPool.name,
            coordinate: zone.destinationPool.coordinate,
          }
        : undefined,
      confirmationStrategy: zone.confirmationStrategy,
      notificationStrategy: zone.notificationStrategy,
      mergeStrategy: zone.mergeStrategy,
      sortSteps: zone.sortSteps,
      maxParallelOpenOrders: zone.maxParallelOpenOrders,
      sourceConfirmationPattern: zone.sourceConfirmationPattern,
      sourceOptionalConfirmation: zone.sourceOptionalConfirmation,
      destinationConfirmationPattern: zone.destinationConfirmationPattern,
      destinationOptionalConfirmation: zone.destinationOptionalConfirmation,
      autoConclude: zone.autoConclude,
      autoDelete: zone.autoDelete,
      sourceValueExtenders: zone.sourceValueExtenders,
      destinationValueExtenders: zone.destinationValueExtenders,
      buttonConfirmationEventTopic: zone.buttonConfirmationEventTopic ?? '',
      confirmationTrigger: zone.confirmationTrigger,
    };
  }
}
