import { withStyles, WithStyles } from '@mui/styles';
import { ApiStore } from '../Stores/ApiStore';
import { NavigationStyles } from '../Styles/NavigationStyles';
import React, { ForwardedRef } from 'react';
import { inject, observer } from 'mobx-react';
import { CancelableFetchPromises, cancelFetchPromises } from '../Helper/PromiseHelper';
import { request } from '../Helper/FetchHandler';
import { enqueueSnackbar } from 'notistack';
import { EslManagerPrivateRoute, HttpMethod } from '@ekkogmbh/apisdk';
import { Slide } from '@mui/material';
import { CoordinateScopeStore } from '../Stores/CoordinateScopeStore';
import { StyledSelectField } from './Forms/StyledSelectField';

interface Stores {
  api: ApiStore;
  coordinateScopeStore: CoordinateScopeStore;
}

interface Props extends WithStyles<typeof NavigationStyles> {
  forwardedRef?: ForwardedRef<HTMLDivElement>;
}

interface State {
  loading: boolean;
  coordinates: readonly string[];
}

@inject('api', 'coordinateScopeStore')
@observer
class NavigationCoordinateSelectComponent extends React.Component<Props, State> {
  state: State = {
    loading: true,
    coordinates: [''],
  };

  get stores(): Stores {
    return this.props as Props & Stores;
  }

  private fetchPromises: CancelableFetchPromises = {};

  public async componentDidMount(): Promise<void> {
    const { coordinateScopeStore } = this.stores;

    if (coordinateScopeStore.isScoped) {
      await this.fetchCoordinates();
    }
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private async fetchCoordinates(): Promise<void> {
    this.setState({ loading: true });

    const { api } = this.stores;

    let coordinates: string[] = [];
    try {
      coordinates = await request<string[]>(
        api,
        enqueueSnackbar,
        this.fetchPromises,
        api.getAccessibleCoordinates(undefined, false),
        EslManagerPrivateRoute.ACCESSIBLE_COORDINATES,
        HttpMethod.GET,
      );
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    this.setState({ loading: false, coordinates: ['', ...coordinates] });
  }

  private onSelect(value: number) {
    const { coordinates } = this.state;
    const { coordinateScopeStore } = this.stores;

    coordinateScopeStore.coordinate = coordinates[value];
  }

  public render() {
    const { coordinates, loading } = this.state;
    const { coordinateScopeStore } = this.stores;
    const { isScoped } = coordinateScopeStore;

    return (
      <Slide direction={'down'} in={isScoped && !loading} mountOnEnter unmountOnExit>
        <StyledSelectField
          native
          label={'Coordinate'}
          disabled={coordinates.length < 1}
          onChange={(e) => this.onSelect(e.target.value as number)}
          value={coordinates.indexOf(coordinateScopeStore.coordinate)}
          forwardedRef={this.props.forwardedRef}
          parentStyle={{ width: '15%' }}
        >
          {coordinates.map((coord, i) => (
            <option key={i} value={i}>
              {coord}
            </option>
          ))}
        </StyledSelectField>
      </Slide>
    );
  }
}

export const NavigationCoordinateSelect = withStyles(NavigationStyles)(NavigationCoordinateSelectComponent);
