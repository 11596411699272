import { Grid, Paper, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { ZoneManagementStyles } from '../Styles/ZoneManagementStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { Component } from 'react';
import { inject } from 'mobx-react';
import { ContentPanelDefinition, ContentPanels } from 'src/Common/Components/ContentPanels';
import { Add } from '@mui/icons-material';
import { ZonePanel, ZonePanelProps } from './ZonePanel';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { NavigationStore } from 'src/Common/Stores/NavigationStore';
import { ApiStore, Permissions } from 'src/Common/Stores/ApiStore';
import { CancelableFetchPromises, cancelFetchPromises, noop } from 'src/Common/Helper/PromiseHelper';
import {
  Zone,
  EslManagerPublicRouteV2,
  HttpMethod,
  Pagination,
  PaginationResponse,
  ZonePayload,
} from '@ekkogmbh/apisdk';
import { DataTable, DataTableFilterFields, DataTableSortFieldMap } from 'src/Common/Components/DataTable';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { materialDatatableColumnDefinitions } from './ZoneColumnDefinitions';
import { request } from 'src/Common/Helper/FetchHandler';
import { ZoneStore } from '../Stores/ZoneStore';
import { GenericDialog } from 'src/Common/Components/GenericDialog';
import { NotificationStrategyStore } from '../Stores/NotificationStrategyStore';

const styles = ZoneManagementStyles;

const stores = ['api', 'searchContentStore', 'navigationStore', 'zoneStore', 'notificationStrategyStore'];

interface ZoneManagementContentActions {
  updateZone?: (Zone: Zone) => void;
}

export interface ZoneManagementContentActionHandlers {
  blueprints: (zone: Zone) => void;
  edit: (zone: Zone) => void;
  delete: (zone: Zone) => void;
}

export interface ZoneManagementContentStores {
  api: ApiStore;
  searchContentStore: SearchContentStore;
  navigationStore: NavigationStore;
  zoneStore: ZoneStore;
  notificationStrategyStore: NotificationStrategyStore;
}

export interface ZoneManagementContentState {
  deletableZone?: Zone;
  forceDelete: boolean;
}

export interface ZoneManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

export type ZoneManagementContentPropsWithStores = ZoneManagementContentProps & ZoneManagementContentStores;

@inject(...stores)
class ZoneManagementContentComponent extends Component<ZoneManagementContentProps, ZoneManagementContentState> {
  private readonly filterFields: DataTableFilterFields<Zone> = ['name'];
  private readonly sortFieldMap: DataTableSortFieldMap<Zone> = { name: 'Z.name' };
  private readonly actions: ZoneManagementContentActions = {};

  private fetchPromises: CancelableFetchPromises = {};

  get stores(): ZoneManagementContentStores {
    return this.props as ZoneManagementContentPropsWithStores;
  }

  public state: ZoneManagementContentState = {
    forceDelete: false,
  };

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchZones = async (pagination: Pagination): Promise<PaginationResponse<Zone>> => {
    const { api } = this.stores;

    return await request<PaginationResponse<Zone>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getZones(pagination),
      EslManagerPublicRouteV2.ZONES,
      HttpMethod.GET,
    );
  };

  private onSave = async (savableZone: ZonePayload, allowOverwrite: boolean): Promise<void> => {
    const { api, navigationStore, searchContentStore } = this.stores;

    await request<Zone>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.saveZone(savableZone, allowOverwrite),
      EslManagerPublicRouteV2.ZONE,
      HttpMethod.PUT,
      { 200: 'Zone saved.' },
    );

    navigationStore.scrollTop();
    searchContentStore.emitRefresh();
  };

  private onEdit = async (zone: Zone): Promise<void> => {
    const { navigationStore } = this.stores;
    const { updateZone } = this.actions;

    if (updateZone) {
      updateZone(zone);
      navigationStore!.scrollTop();
    }
  };

  private onDeleteConfirm = async () => {
    const { deletableZone, forceDelete } = this.state;
    const { api, searchContentStore } = this.stores;

    if (!deletableZone) {
      return;
    }

    await request<void>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.deleteZone(deletableZone, forceDelete),
      EslManagerPublicRouteV2.ZONE,
      HttpMethod.DELETE,
      { 200: 'Zone deleted.' },
      () => {
        this.setState({ deletableZone: undefined, forceDelete: false }, () => searchContentStore.emitRefresh());
      },
      () => {
        this.setState({ forceDelete: true });
      },
    );
  };

  private createPanel = (): ContentPanelDefinition<ZonePanelProps> => {
    const { zoneStore, notificationStrategyStore } = this.stores;
    return {
      name: 'Add',
      icon: Add,
      isHidden: false,
      panelComponent: ZonePanel,
      panelProps: {
        closeHandler: noop,
        saveHandler: this.onSave,
      },
      permission: Permissions.ZONES_WRITE,
      toggleOffCallback: zoneStore.resetStore,
      expandHandler: (expandCallback: () => void) => {
        const { updateZone } = this.actions;
        if (updateZone === undefined) {
          this.actions.updateZone = async (zone: Zone) => {
            expandCallback();
            zoneStore.setEditableZone(zone);
            notificationStrategyStore.resetStore(zone.notificationStrategy);
          };
        }
      },
    };
  };

  public actionHandlerBlueprints = (zone: Zone) => {
    const { navigationStore } = this.stores;
    navigationStore.scrollTop();

    this.props.history.push('/picking/zones/' + zone.name + '/' + zone.coordinate);
  };

  public render() {
    const { deletableZone, forceDelete } = this.state;

    const columnDefinition: MUIDataTableColumnDef[] = materialDatatableColumnDefinitions.map((defFn) =>
      defFn(this.state, this.props as ZoneManagementContentPropsWithStores, {
        blueprints: this.actionHandlerBlueprints,
        edit: this.onEdit,
        delete: (Zone) => this.setState({ deletableZone: Zone }),
      }),
    );

    return (
      <Grid item xs={12}>
        <ContentPanels panels={[this.createPanel()]} />

        {deletableZone && (
          <GenericDialog
            type="confirmation"
            maxWidth={'sm'}
            fullWidth={true}
            centered={true}
            open={!!deletableZone}
            text={
              <>
                {forceDelete && (
                  <>
                    <Typography color={'error'}>{'open orders found'}</Typography>
                    <Typography color={'primary'}>{'delete anyway?'}</Typography>
                  </>
                )}
                {!forceDelete && <Typography color={'primary'}>{'confirm deletion'}</Typography>}
              </>
            }
            title={`Delete "${deletableZone.name}" ?`}
            onClose={() => this.setState({ deletableZone: undefined, forceDelete: false })}
            onConfirm={this.onDeleteConfirm}
            timedOkButton={forceDelete}
          />
        )}

        <Paper>
          <DataTable
            columns={columnDefinition}
            fetchItems={this.fetchZones}
            filterFields={this.filterFields}
            sortFieldMap={this.sortFieldMap}
            options={{
              sortOrder: { name: materialDatatableColumnDefinitions[0].name, direction: 'desc' },
            }}
          />
        </Paper>
      </Grid>
    );
  }
}

const RouterWrapped = withRouter<ZoneManagementContentProps, typeof ZoneManagementContentComponent>(
  ZoneManagementContentComponent,
);
const StyleWrapped = withStyles(styles)(RouterWrapped);

export const ZoneManagementContent = StyleWrapped;
