import {
  CompartmentSelectionStrategy,
  CompartmentSelector,
  CompartmentSelectorConfiguration,
  MinMaxType,
} from '@ekkogmbh/apisdk';
import {
  CheckCircle as Check,
  Delete,
  ExpandLess,
  ExpandMore,
  HighlightOff as Close,
  InfoOutlined,
} from '@mui/icons-material';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Alert,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React, { ChangeEvent } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { ListForm } from 'src/Common/Components/Forms/ListForm';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { CoordinateInput } from '../../Common/Components/CoordinateInput';
import { StyledSelectField } from '../../Common/Components/Forms/StyledSelectField';
import { DynamicStepper, DynamicStepperStep } from '../../Common/Components/Stepper/DynamicStepper';
import { NavigationStore } from '../../Common/Stores/NavigationStore';
import { CompartmentSelectorState, CompartmentSelectorStore } from '../Stores/CompartmentSelectorStore';

const styles = FormStyles;

const TableCell = styled(MuiTableCell)(() => ({
  border: 0,
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

interface ConfigPart {
  order: number;
  key: keyof CompartmentSelectorConfiguration;
  title: string | React.JSX.Element;
  description: string | React.JSX.Element;
  enabled: boolean;
  element: React.JSX.Element;
}

interface CompartmentSelectorPanelStores {
  api: ApiStore;
  compartmentSelectorStore: CompartmentSelectorStore;
  navigationStore: NavigationStore;
}

interface CompartmentSelectorPanelState {
  loading: boolean;
  finished: boolean;
  activeStep: number;
  expanded: number;
}

export interface CompartmentSelectorPanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (compartmentSelector: CompartmentSelector) => Promise<CompartmentSelector>;
}

const TypographyWithBrightBackground = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.text.primary,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
})) as typeof Typography; // workaround

const TypographyWithDarkBackground = styled(TypographyWithBrightBackground)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  color: 'white',
})) as typeof Typography; // workaround

@inject('api', 'compartmentSelectorStore', 'navigationStore')
@observer
class CompartmentSelectorPanelComponent extends React.Component<
  CompartmentSelectorPanelProps,
  CompartmentSelectorPanelState
> {
  public state: CompartmentSelectorPanelState = {
    activeStep: 0,
    finished: false,
    loading: false,
    expanded: 0,
  };

  get stores(): CompartmentSelectorPanelStores {
    return this.props as CompartmentSelectorPanelProps & CompartmentSelectorPanelStores;
  }

  public componentWillUnmount(): void {
    const { compartmentSelectorStore } = this.stores;
    compartmentSelectorStore.resetStore();
  }

  public handleNext = async () => {
    const { activeStep } = this.state;
    const { navigationStore } = this.stores;

    navigationStore.scrollTop();

    this.setState({ activeStep: activeStep + 1 });
  };

  public handleBack = async () => {
    const { activeStep } = this.state;
    const { navigationStore } = this.stores;

    navigationStore.scrollTop();

    this.setState({ activeStep: activeStep - 1 });
  };

  public handleExpand = (panel: number) => (_: React.SyntheticEvent, newExpanded: boolean) => {
    this.setState({ expanded: newExpanded ? panel : 0 });
  };

  public handleReset = async () => {
    const { compartmentSelectorStore, navigationStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;

    navigationStore.scrollTop();

    if (editableCompartmentSelector !== undefined) {
      compartmentSelectorStore.setEditableCompartmentSelector(editableCompartmentSelector);
    } else {
      this.componentWillUnmount();
    }

    this.setState({ activeStep: 0, loading: false, finished: false, expanded: 0 });
  };

  public handleSave = async () => {
    const { saveHandler, closeHandler } = this.props;
    const { compartmentSelectorStore, navigationStore } = this.stores;

    navigationStore.scrollTop();

    const payload = compartmentSelectorStore.state as CompartmentSelector;

    this.setState({ loading: true }, async () => {
      await saveHandler(payload);
      this.handleReset();
      closeHandler();
    });
  };

  public configWrapper = (form: React.JSX.Element, helpText: React.JSX.Element): React.JSX.Element => (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Stack sx={{ mr: 2 }}>{form}</Stack>
      </Grid>
      <Grid item xs={12} md={6} sx={{ px: 2 }}>
        {helpText}
      </Grid>
    </Grid>
  );

  // refactor into own component
  public exampleWrapper = (
    configName: string | React.JSX.Element,
    input: string | React.JSX.Element,
    configText: string | React.JSX.Element,
    inputText: string | React.JSX.Element,
    resultText: string | React.JSX.Element,
    additional?: Array<[string | React.JSX.Element, string | React.JSX.Element]> | React.JSX.Element,
    description?: string | React.JSX.Element,
    resultTextOverride?: string | React.JSX.Element,
  ): React.JSX.Element => (
    <Alert variant={'outlined'} icon={<InfoOutlined sx={{ fontSize: 32 }} />} severity={'info'}>
      <Stack>
        {description && <Typography variant={'caption'}>{description}</Typography>}
        <Typography variant={'button'} sx={{ fontWeight: 700, p: 2 }}>
          Example
        </Typography>
        <Stack>
          <Box sx={{ display: 'flex', p: 2 }}>
            <Table sx={{ width: 'auto' }}>
              <TableBody>
                <TableRow>
                  <TableCell align={'right'} sx={{ border: 0 }}>
                    <Typography sx={{ fontWeight: 700 }}>{input}</Typography>
                  </TableCell>
                  <TableCell>
                    <TypographyWithDarkBackground component={'span'}>{inputText}</TypographyWithDarkBackground>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align={'right'} sx={{ border: 0 }}>
                    <Typography sx={{ fontWeight: 700 }}>{configName}</Typography>
                  </TableCell>
                  <TableCell>
                    <TypographyWithBrightBackground component={'span'}>{configText}</TypographyWithBrightBackground>
                  </TableCell>
                </TableRow>
                {additional &&
                  additional instanceof Array &&
                  additional.length > 0 &&
                  additional.map(([a, b], i) => (
                    <TableRow key={`example-row-${i}`}>
                      <TableCell align={'right'} sx={{ border: 0 }}>
                        <Typography sx={{ fontWeight: 700 }}>{a}</Typography>
                      </TableCell>
                      <TableCell>
                        <TypographyWithBrightBackground component={'span'}>{b}</TypographyWithBrightBackground>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
          {additional && !(additional instanceof Array) && <Box sx={{ width: '100%', p: 2 }}>{additional}</Box>}
        </Stack>
        <Typography variant={'button'} sx={{ fontWeight: 700, p: 2 }} component={'div'}>
          Result
        </Typography>
        <Box sx={{ display: 'flex', p: 2 }}>
          {resultTextOverride ? (
            resultTextOverride
          ) : (
            <Stack direction={'row'} alignItems={'center'} sx={{ pl: 3 }}>
              <TypographyWithDarkBackground>{inputText}</TypographyWithDarkBackground>
              <Typography sx={{ px: 1, fontWeight: 700, fontSize: '2rem' }}>→</Typography>
              {typeof resultText === 'string' ? (
                <TypographyWithBrightBackground>{resultText}</TypographyWithBrightBackground>
              ) : (
                resultText
              )}
            </Stack>
          )}
        </Box>
      </Stack>
    </Alert>
  );

  public constantConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    return this.configWrapper(
      <StyledTextField
        type={'text'}
        label={'Value'}
        value={configuration.constant as string}
        onChange={(e) => compartmentSelectorStore.setConfigurationValue('constant', e.target.value as string)}
      />,
      this.exampleWrapper('Constant', 'Input', 'always-the-same-text', 'text', 'always-the-same-text'),
    );
  };

  public prefixConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const isByValue = compartmentSelectorStore.state.type === CompartmentSelectionStrategy.VALUE;

    const onChange = (p: string | ChangeEvent<HTMLInputElement>) => {
      if (typeof p === 'string') {
        compartmentSelectorStore.setConfigurationValue('prefix', p);
      } else {
        compartmentSelectorStore.setConfigurationValue('prefix', p.target.value);
      }
    };

    return this.configWrapper(
      isByValue ? (
        <StyledTextField type={'text'} label={'Prefix'} value={configuration.prefix ?? ''} onChange={onChange} />
      ) : (
        <CoordinateInput
          label="Prefix"
          value={configuration.prefix ?? ''}
          onChange={onChange}
          trailingDelimiter="both"
        />
      ),
      this.exampleWrapper(
        'Prefix',
        'Input',
        'prefixed',
        'text',
        <TypographyWithBrightBackground>
          <Stack direction={'row'}>
            <Typography color={'textSecondary'} sx={{ fontStyle: 'italic' }}>
              prefixed
            </Typography>
            <Typography>text</Typography>
          </Stack>
        </TypographyWithBrightBackground>,
      ),
    );
  };

  public suffixConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    return this.configWrapper(
      <StyledTextField
        type={'text'}
        label={'Value'}
        value={configuration.suffix as string}
        onChange={(e) => compartmentSelectorStore.setConfigurationValue('suffix', e.target.value as string)}
      />,
      this.exampleWrapper(
        'Suffix',
        'Input',
        'suffixed',
        'text',
        <TypographyWithBrightBackground>
          <Stack direction={'row'}>
            <Typography>text</Typography>
            <Typography color={'textSecondary'} sx={{ fontStyle: 'italic' }}>
              suffixed
            </Typography>
          </Stack>
        </TypographyWithBrightBackground>,
      ),
    );
  };

  public fieldsConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const onChange = (items: string[]) => {
      // console.log('fieldsConfig onChange', items);
      compartmentSelectorStore.setConfigurationValue(
        'fields',
        items.map((i) => i), // @TODO workaround for mobx
      );
    };

    return this.configWrapper(
      <ListForm items={(configuration.fields as string[]) ?? []} onChange={onChange} />,
      this.exampleWrapper(
        'Fields',
        'Input',
        'Amount',
        '15',
        'X_660_B',
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <MuiTableCell>Coordinate</MuiTableCell>
                <MuiTableCell>Name</MuiTableCell>
                <MuiTableCell>Amount</MuiTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <MuiTableCell>X_660_A</MuiTableCell>
                <MuiTableCell>Schraube</MuiTableCell>
                <MuiTableCell>30</MuiTableCell>
              </TableRow>
              <TableRow>
                <MuiTableCell>X_660_B</MuiTableCell>
                <MuiTableCell>Schraube</MuiTableCell>
                <MuiTableCell>15</MuiTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>,
      ),
    );
  };

  public splitConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const split = configuration.split;
    const prev = split ?? { delimiter: '', position: 1 };
    const { delimiter, position } = prev;

    const form = (
      <>
        <StyledTextField
          type={'text'}
          label={'Delimiter'}
          value={delimiter}
          onChange={(e) =>
            compartmentSelectorStore.setConfigurationValue('split', {
              ...prev,
              delimiter: e.target.value as string,
            })
          }
        />
        <StyledTextField
          numeric
          type={'number'}
          label={'Position'}
          value={position}
          onChange={(e) => {
            const intValue = parseInt(e.target.value);
            const newValue = intValue >= 1 ? intValue : 1;

            compartmentSelectorStore.setConfigurationValue('split', {
              ...prev,
              position: newValue,
            });
          }}
        />
      </>
    );

    return this.configWrapper(
      form,
      this.exampleWrapper(
        'Delimiter',
        'Input',
        ';',
        'split;this;text',
        'text',
        [['Index', '3']],
        <>
          Split your input text by the delimiter character and only use the part that you need. Select the correct part
          by its index. Index of the first item is 1
        </>,
      ),
    );
  };

  public regexConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const regex = configuration.regex;
    const prev = regex ?? { pattern: '', replacement: '' };
    const { pattern, replacement } = prev;

    const form = (
      <>
        <StyledTextField
          type={'text'}
          label={'Pattern'}
          value={pattern}
          onChange={(e) =>
            compartmentSelectorStore.setConfigurationValue('regex', { ...prev, pattern: e.target.value as string })
          }
        />
        <StyledTextField
          type={'text'}
          label={'Replacement'}
          value={replacement}
          onChange={(e) =>
            compartmentSelectorStore.setConfigurationValue('regex', {
              ...prev,
              replacement: e.target.value as string,
            })
          }
        />
      </>
    );

    return this.configWrapper(
      form,
      this.exampleWrapper(
        'Pattern',
        'Input',
        '/([0-9]{3})/',
        'input1234',
        'replacement123',
        [['Replacement', 'replacement$1']],
        <>
          (Programming skills recommended)
          <br />
          Use a regular expression to split your input in groups.
          <br />
          Use groups in your replacement text to generate the new input.
          <br />
          Learn more about regular expressions on https://regex101.com/
        </>,
      ),
    );
  };

  public minmaxConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const minmax = configuration.minmax;
    const prev = minmax ?? { type: MinMaxType.MAX, field: '' };
    const { type, field } = prev;

    const form = (
      <>
        <StyledSelectField
          label={'Type'}
          value={type}
          onChange={(e) =>
            compartmentSelectorStore.setConfigurationValue('minmax', {
              ...prev,
              type: e.target.value as MinMaxType,
            })
          }
        >
          {Object.values(MinMaxType).map((m, i) => (
            <MenuItem key={`minmax-item-${i}`} value={m}>
              <ListItemText primary={m} />
            </MenuItem>
          ))}
        </StyledSelectField>
        <StyledTextField
          type={'text'}
          label={'Field'}
          value={field}
          onChange={(e) =>
            compartmentSelectorStore.setConfigurationValue('minmax', { ...prev, field: e.target.value as string })
          }
          endAdornment={
            <IconButton
              disabled={!compartmentSelectorStore.hasConfiguration('minmax')}
              onClick={() => compartmentSelectorStore.removeConfigurationValue('minmax')}
            >
              <Delete />
            </IconButton>
          }
        />
      </>
    );

    return this.configWrapper(
      form,
      this.exampleWrapper(
        'Field',
        'Input',
        'Amount',
        'Schraube',
        '',
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <MuiTableCell>Coordinate</MuiTableCell>
                <MuiTableCell>Name</MuiTableCell>
                <MuiTableCell>Amount</MuiTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <MuiTableCell>X_660_A</MuiTableCell>
                <MuiTableCell>Schraube</MuiTableCell>
                <MuiTableCell>30</MuiTableCell>
              </TableRow>
              <TableRow>
                <MuiTableCell>X_660_B</MuiTableCell>
                <MuiTableCell>Schraube</MuiTableCell>
                <MuiTableCell>30</MuiTableCell>
              </TableRow>
              <TableRow>
                <MuiTableCell>X_660_C</MuiTableCell>
                <MuiTableCell>Schraube</MuiTableCell>
                <MuiTableCell>5</MuiTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>,
        undefined,
        <Stack>
          <Box sx={{ display: 'flex', p: 2 }}>
            <Stack direction={'row'} alignItems={'center'} sx={{ pl: 3 }}>
              <TypographyWithDarkBackground sx={{ mr: 1 }}>Schraube</TypographyWithDarkBackground>
              <TypographyWithDarkBackground>Type: maximize</TypographyWithDarkBackground>
              <Typography sx={{ px: 1, fontWeight: 700, fontSize: '2rem' }}>→</Typography>
              <TypographyWithBrightBackground sx={{ mr: 1 }}>X_660_A</TypographyWithBrightBackground>
              <TypographyWithBrightBackground>X_660_B</TypographyWithBrightBackground>
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', p: 2 }}>
            <Stack direction={'row'} alignItems={'center'} sx={{ pl: 3 }}>
              <TypographyWithDarkBackground sx={{ mr: 1 }}>Schraube</TypographyWithDarkBackground>
              <TypographyWithDarkBackground>Type: minimize</TypographyWithDarkBackground>
              <Typography sx={{ px: 1, fontWeight: 700, fontSize: '2rem' }}>→</Typography>
              <TypographyWithBrightBackground>X_660_C</TypographyWithBrightBackground>
            </Stack>
          </Box>
        </Stack>,
      ),
    );
  };

  public inputValidationConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const validation = configuration.inputValidation as Record<string, string>;
    const prev = validation ?? { pattern: '', errorText: '' };
    const { pattern, errorText } = prev;

    const form = (
      <>
        <StyledTextField
          type={'text'}
          label={'Pattern'}
          value={pattern}
          onChange={(e) =>
            compartmentSelectorStore.setConfigurationValue('inputValidation', {
              ...prev,
              pattern: e.target.value as string,
            })
          }
        />
        <StyledTextField
          type={'text'}
          label={'Error Text'}
          value={errorText}
          onChange={(e) =>
            compartmentSelectorStore.setConfigurationValue('inputValidation', {
              ...prev,
              errorText: e.target.value as string,
            })
          }
        />
      </>
    );

    return this.configWrapper(
      form,
      this.exampleWrapper(
        'Error Text',
        'Pattern',
        'Invalid Input',
        '/[a-zA-Z0-9]{8}/',
        'text',
        undefined,
        <>
          (Programming skills recommended)
          <br />
          Use a regular expression to validate your input.
          <br />
          If the validation fails your error message will be displayed to the user.
          <br />
          For the validation the match of the regular expression must be equal to the input.
        </>,
        <Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 3 }}>
            <TypographyWithDarkBackground>input123</TypographyWithDarkBackground>
            <Typography sx={{ px: 1, fontWeight: 700, fontSize: '2rem' }}>→</Typography>
            <TypographyWithBrightBackground>success</TypographyWithBrightBackground>
          </Stack>
          <Stack direction={'row'} alignItems={'center'} sx={{ pl: 3 }}>
            <TypographyWithDarkBackground>input12345</TypographyWithDarkBackground>
            <Typography sx={{ px: 1, fontWeight: 700, fontSize: '2rem' }}>→</Typography>
            <TypographyWithBrightBackground sx={(theme) => ({ color: theme.palette.error.main })}>
              Invalid Input
            </TypographyWithBrightBackground>
          </Stack>
        </Stack>,
      ),
    );
  };

  public batchConfig = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { configuration } = compartmentSelectorStore.state;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === 'single') {
        compartmentSelectorStore.removeConfigurationValue('batch');
      } else {
        compartmentSelectorStore.setConfigurationValue('batch', { delimiter: '' });
      }
    };

    const batch = (configuration.batch as { delimiter: string }) ?? {};
    const form = (
      <Box sx={{ pl: 10 }}>
        <Box sx={{ pb: 2 }}>Split the input text by a delimiter and use each part as its own input.</Box>
        <FormControl>
          <RadioGroup
            aria-labelledby="radio-group"
            value={compartmentSelectorStore.hasConfiguration('batch') ? 'multiple' : 'single'}
            name="radio-group"
            onChange={onChange}
          >
            <FormControlLabel value="single" control={<Radio />} label="Single" />
            <FormControlLabel value="multiple" control={<Radio />} label="Multiple" />
          </RadioGroup>
        </FormControl>
        {compartmentSelectorStore.hasConfiguration('batch') && (
          <StyledTextField
            type={'text'}
            label={'delimiter'}
            value={batch?.delimiter}
            onChange={(e) =>
              compartmentSelectorStore.setConfigurationValue('batch', { delimiter: e.target.value as string })
            }
          />
        )}
      </Box>
    );

    return this.configWrapper(
      form,
      this.exampleWrapper(
        'Delimiter',
        'Input',
        ';',
        'input1;input2;input3',
        <Stack direction={'row'} spacing={1}>
          <TypographyWithBrightBackground>input1</TypographyWithBrightBackground>
          <TypographyWithBrightBackground>input2</TypographyWithBrightBackground>
          <TypographyWithBrightBackground>input3</TypographyWithBrightBackground>
        </Stack>,
      ),
    );
  };

  public configParts = (): ConfigPart[] => {
    const { compartmentSelectorStore } = this.stores;
    const { type } = compartmentSelectorStore.state;

    const configParts: ConfigPart[] = [];

    configParts.push({
      order: 10,
      key: 'prefix',
      enabled: compartmentSelectorStore.hasConfiguration('prefix'),
      title: <Typography>Prefix</Typography>,
      description: <Typography variant={'caption'}>Always add a constant text in front of your input.</Typography>,
      element: this.prefixConfig(),
    });
    configParts.push({
      order: 20,
      key: 'constant',
      enabled: compartmentSelectorStore.hasConfiguration('constant'),
      title: <Typography>Constant</Typography>,
      description: (
        <Typography variant={'caption'}>Will always use the constant text and ignore the actual input.</Typography>
      ),
      element: this.constantConfig(),
    });
    configParts.push({
      order: 30,
      key: 'suffix',
      enabled: compartmentSelectorStore.hasConfiguration('suffix'),
      title: <Typography>Suffix</Typography>,
      description: <Typography variant={'caption'}>Always add a constant text behind your input.</Typography>,
      element: this.suffixConfig(),
    });
    configParts.push({
      order: 40,
      key: 'split',
      enabled: compartmentSelectorStore.hasConfiguration('split'),
      title: <Typography>Split</Typography>,
      description: (
        <Typography variant={'caption'}>
          Splits the selector input and uses only the value at the given position.
        </Typography>
      ),
      element: this.splitConfig(),
    });
    configParts.push({
      order: 50,
      key: 'regex',
      enabled: compartmentSelectorStore.hasConfiguration('regex'),
      title: <Typography>Regular Expression</Typography>,
      description: <Typography variant={'caption'}>Use a regex pattern/replacement to transform the input.</Typography>,
      element: this.regexConfig(),
    });
    configParts.push({
      order: 60,
      key: 'inputValidation',
      enabled: compartmentSelectorStore.hasConfiguration('inputValidation'),
      title: <Typography>Validation</Typography>,
      description: <Typography variant={'caption'}>Limit the inputs by matching against a regex pattern.</Typography>,
      element: this.inputValidationConfig(),
    });

    if (type === CompartmentSelectionStrategy.VALUE) {
      configParts.push({
        order: 5,
        key: 'fields',
        enabled: compartmentSelectorStore.hasConfiguration('fields'),
        title: <Typography>Fields</Typography>,
        description: <Typography variant={'caption'}>Match against compartment fields for the selection.</Typography>,
        element: this.fieldsConfig(),
      });
      configParts.push({
        order: 90,
        key: 'minmax',
        enabled: compartmentSelectorStore.hasConfiguration('minmax'),
        title: <Typography>MinMax</Typography>,
        description: (
          <Typography variant={'caption'}>
            Reduce your found matches by only selecting the one with the highest or lowest value in a certain field.
          </Typography>
        ),
        element: this.minmaxConfig(),
      });
    }

    return configParts;
  };

  public assembleConfigurationForm(): React.JSX.Element {
    const { expanded } = this.state;

    const configParts = this.configParts();

    return (
      <>
        {configParts
          .sort((a, b) => a.order - b.order)
          .map((part, i) => (
            <Accordion
              key={`config-accordion-${i}`}
              disableGutters
              square
              elevation={0}
              expanded={expanded === part.order}
              onChange={this.handleExpand(part.order)}
            >
              <AccordionSummary expandIcon={null}>
                <Grid container sx={{ display: 'flex', flex: 1, alignItems: 'center', width: '100%' }}>
                  <Grid item xs={12} md={6}>
                    <Stack direction={'row'}>
                      {part.enabled ? (
                        <Check color={'secondary'} sx={{ fontSize: 32 }} />
                      ) : (
                        <Close color={'secondary'} sx={{ fontSize: 32 }} />
                      )}
                      {expanded === part.order ? (
                        <ExpandLess color={'secondary'} sx={{ fontSize: 32, ml: 4 }} />
                      ) : (
                        <ExpandMore color={'secondary'} sx={{ fontSize: 32, ml: 4 }} />
                      )}
                      <Box sx={{ alignSelf: 'center', pl: 2, flex: 1 }}>{part.title}</Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6} sx={{ px: 2 }}>
                    {part.description}
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>{part.element}</AccordionDetails>
            </Accordion>
          ))}
      </>
    );
  }

  public isNextDisabled = (step: DynamicStepperStep): boolean => {
    const { compartmentSelectorStore } = this.stores;

    const { name, coordinate, configuration } = compartmentSelectorStore.state;

    switch (step.title) {
      case 'Identifier':
        return name === '' || coordinate === '';
      case 'Batch':
        if (compartmentSelectorStore.hasConfiguration('batch')) {
          return configuration.batch?.delimiter === '';
        }
        return false;
      case 'Type':
      case 'Configuration':
        return false;
      case 'Overview':
        return true;
    }

    return false;
  };

  public overview = (): React.JSX.Element => {
    const { compartmentSelectorStore } = this.stores;
    const { name, coordinate, configuration, type } = compartmentSelectorStore.state;

    const configParts = this.configParts();

    const config = configParts
      .sort((a, b) => a.order - b.order)
      .map((configPart, i) => {
        let body: string | React.JSX.Element | React.JSX.Element[] | undefined;

        if (!configPart.enabled) {
          return null;
        }

        switch (configPart.key) {
          case 'fields':
            body = configuration.fields?.map((field, index) => (
              <Chip key={`overview-chip-${index}`} label={field} sx={{ mr: 1 }} />
            ));
            break;
          case 'inputValidation':
            body = (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Pattern</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{configuration.inputValidation?.pattern}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Error Text</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{configuration.inputValidation?.errorText}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
            break;
          case 'split':
            body = (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Delimiter</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{configuration.split?.delimiter}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Position</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{configuration.split?.position}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
            break;
          case 'regex':
            body = (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Pattern</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{configuration.regex?.pattern}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Replacement</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{configuration.regex?.replacement}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
            break;
          case 'minmax':
            body = (
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{configuration.minmax?.type}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Field</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{configuration.minmax?.field}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            );
            break;
          case 'prefix':
          case 'suffix':
          case 'constant':
            body = configuration[configPart.key];
            break;
        }

        return (
          <TableRow key={`overview-row-${i}`}>
            <TableCell align={'right'} sx={{ border: 0 }}>
              <Typography sx={{ fontWeight: 700 }}>{configPart.title}</Typography>
            </TableCell>
            <TableCell sx={{ py: 2 }}>
              {typeof body === 'string' ? <Typography variant={'body1'}>{body}</Typography> : body}
            </TableCell>
          </TableRow>
        );
      });

    return (
      <Stack direction={'row'} sx={{ justifyContent: 'space-around' }}>
        <Stack direction={'column'}>
          <Box>
            <Stack direction={'row'} sx={{ p: 2, justifyContent: 'center' }}>
              <Typography variant={'h4'}>Identifier</Typography>
            </Stack>
            <Stack sx={{ p: 2 }}>
              <Table sx={{ width: 'auto' }}>
                <TableBody>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{name}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Coordinate</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{coordinate}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
            <Stack direction={'row'} sx={{ p: 2, justifyContent: 'center' }}>
              <Typography variant={'h4'}>Batch</Typography>
            </Stack>
            <Stack sx={{ p: 2 }}>
              <Table sx={{ width: 'auto' }}>
                <TableBody>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>
                        {compartmentSelectorStore.hasConfiguration('batch') ? 'Multiple' : 'Single'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {compartmentSelectorStore.hasConfiguration('batch') && (
                    <TableRow>
                      <TableCell align={'right'} sx={{ border: 0 }}>
                        <Typography sx={{ fontWeight: 700 }}>Delimiter</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography component={'span'}>{configuration.batch?.delimiter}</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Stack>
            <Stack direction={'row'} sx={{ p: 2, justifyContent: 'center' }}>
              <Typography variant={'h4'}>Selector-Type</Typography>
            </Stack>
            <Stack sx={{ p: 2 }}>
              <Table sx={{ width: 'auto' }}>
                <TableBody>
                  <TableRow>
                    <TableCell align={'right'} sx={{ border: 0 }}>
                      <Typography sx={{ fontWeight: 700 }}>Type</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component={'span'}>{type}</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </Box>
        </Stack>
        <Stack direction={'column'}>
          <Stack>
            <Stack direction={'row'} sx={{ p: 2, justifyContent: 'center' }}>
              <Typography variant={'h4'}>Configuration</Typography>
            </Stack>
            <TableContainer>
              <Table>
                <TableBody>{config}</TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Stack>
    );
  };

  public getSteps = (): DynamicStepperStep[] => {
    const { compartmentSelectorStore } = this.stores;
    const { editableCompartmentSelector } = compartmentSelectorStore;
    const isDisabled = editableCompartmentSelector !== undefined;
    const { name, coordinate, configuration, type } = compartmentSelectorStore.state;

    // @TODO
    const updateCoordinate = (newCoordinate: string) => {
      // console.log(newCoordinate);
      const previousCoordinate = coordinate;

      const newState: Partial<CompartmentSelectorState> = { coordinate: newCoordinate };

      // make the prefix behave like before EKKO-1307 and tie it to the coordinate (if the prefix isn't customized)
      const customizedPrefix =
        Object.keys(configuration).includes('prefix') && configuration.prefix !== previousCoordinate;

      if (type === 'select-by-coordinate' && !customizedPrefix) {
        newState.configuration = { ...configuration, prefix: newCoordinate };
      }

      compartmentSelectorStore.setState(newState);
    };

    // @TODO
    const updateSelectorType = (newType: CompartmentSelectionStrategy) => {
      const newConfig: CompartmentSelectorConfiguration = { ...configuration };

      // make the prefix behave like before EKKO-1307 and tie it to the coordinate (if the prefix is unset)
      const noPrefix = !Object.keys(configuration).includes('prefix');

      if (newType === CompartmentSelectionStrategy.COORDINATE && noPrefix) {
        newConfig.prefix = coordinate;
      }

      switch (newType) {
        case CompartmentSelectionStrategy.COORDINATE:
          delete newConfig.fields;
          delete newConfig.minmax;
          break;
        case CompartmentSelectionStrategy.VALUE:
          const customizedPrefix = Object.keys(configuration).includes('prefix') && configuration.prefix !== coordinate;

          if (!customizedPrefix) {
            delete newConfig.prefix;
          }
          break;
      }

      compartmentSelectorStore.setState({ type: newType, configuration: newConfig });
    };

    const typeOptions = Object.values(CompartmentSelectionStrategy).map((value, index) => (
      <option key={`selector-strategy-${index}`} value={value}>
        {value}
      </option>
    ));

    return [
      {
        title: 'Identifier',
        titleProps: { variant: 'h6' },
        description: (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Table sx={{ width: 'auto' }}>
              <TableBody>
                <TableRow>
                  <TableCell align={'right'} sx={{ border: 0 }}>
                    <Typography variant={'body1'} sx={{ visibility: name ? 'visible' : 'hidden' }}>
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'caption'}>{name}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align={'right'}>
                    <Typography variant={'body1'} sx={{ visibility: coordinate ? 'visible' : 'hidden' }}>
                      Coordinate
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'caption'}>{coordinate}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ),
        elementCallback: (): React.JSX.Element => (
          <Stack spacing={1} sx={{ alignItems: 'center' }}>
            <Box sx={{ width: '50%' }}>
              <StyledTextField
                type={'text'}
                label={'Name'}
                value={name}
                disabled={isDisabled}
                onChange={(e) => compartmentSelectorStore.setState({ name: e.target.value })}
              />
            </Box>
            <Box sx={{ width: '50%' }}>
              <CoordinateInput
                value={coordinate}
                disabled={isDisabled}
                onChange={updateCoordinate}
                trailingDelimiter={false}
              />
            </Box>
          </Stack>
        ),
      },
      {
        title: 'Batch',
        titleProps: { variant: 'h6' },
        description: (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Table sx={{ width: 'auto' }}>
              <TableBody>
                <TableRow>
                  <TableCell align={'right'}>
                    <Typography variant={'body1'}>Type</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'caption'}>
                      {compartmentSelectorStore.hasConfiguration('batch') ? 'Multiple' : 'Single'}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align={'right'}>
                    <Typography
                      variant={'body1'}
                      sx={{ visibility: compartmentSelectorStore.hasConfiguration('batch') ? 'visible' : 'hidden' }}
                    >
                      {compartmentSelectorStore.hasConfiguration('batch') ? 'Batch Delimiter' : ''}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'caption'}>{configuration.batch?.delimiter}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ),
        elementCallback: (): React.JSX.Element => (
          <Grid container>
            <Grid item xs={12} sx={{ p: 1 }}>
              <Grid container sx={{ display: 'flex', flex: 1, alignItems: 'center', width: '100%' }}>
                <Grid item xs={12}>
                  {this.batchConfig()}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ),
      },
      {
        title: 'Selector-Type',
        titleProps: { variant: 'h6' },
        description: (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Table sx={{ width: 'auto' }}>
              <TableBody>
                <TableRow>
                  <TableCell align={'right'} sx={{ border: 0 }}>
                    <Typography variant={'body1'} sx={{ visibility: type ? 'visible' : 'hidden' }}>
                      Type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant={'caption'}>{type}</Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        ),
        elementCallback: (): React.JSX.Element => (
          <Stack spacing={1} sx={{ alignItems: 'center' }}>
            <Box sx={{ width: '50%' }}>
              <StyledSelectField
                native
                value={type}
                onChange={(e) => updateSelectorType(e.target.value as CompartmentSelectionStrategy)}
                label="Type"
              >
                {typeOptions}
              </StyledSelectField>
            </Box>
          </Stack>
        ),
      },
      {
        title: 'Configuration',
        titleProps: { variant: 'h6' },
        elementCallback: (): React.JSX.Element => <>{this.assembleConfigurationForm()}</>,
      },
      {
        title: 'Overview',
        titleProps: { variant: 'h6' },
        elementCallback: (): React.JSX.Element => <>{this.overview()}</>,
      },
    ];
  };

  public render() {
    const { closeHandler } = this.props;
    const { activeStep, finished, loading } = this.state;

    const steps = this.getSteps();

    const finalStep = steps.length - 1;
    const isNextDisabled = this.isNextDisabled(steps[activeStep]);

    return (
      <Grid container alignContent="stretch">
        <Grid item xs={12} sx={{ px: 3, py: 2 }}>
          <div style={{ display: loading ? 'block' : 'none' }}>
            <Grid container spacing={2} alignItems="stretch">
              <Grid
                item
                xs={12}
                style={{
                  height: 496,
                  position: 'relative',
                }}
              >
                <div
                  style={{
                    top: '50%',
                    marginTop: -48,
                    position: 'absolute',
                    width: '100%',
                  }}
                >
                  <CheckmarkSpinner complete={finished} failure={false} />
                </div>
              </Grid>
            </Grid>
          </div>

          {!loading && <DynamicStepper alternativeLabel activeStep={activeStep} steps={steps} minHeight={350} />}
        </Grid>
        <Grid item xs={12}>
          <FormPanelButtons
            cancelHandler={closeHandler}
            finished={finished}
            resetHandler={this.handleReset}
            backHandler={this.handleBack}
            nextHandler={this.handleNext}
            saveHandler={this.handleSave}
            isSaveDisabled={activeStep !== finalStep}
            isBackDisabled={activeStep === 0}
            isNextDisabled={isNextDisabled}
            isDeleteDisabled={true}
            isDeleteHidden={true}
          />
        </Grid>
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(CompartmentSelectorPanelComponent);

export const CompartmentSelectorPanel = StyleWrapped;
