import { CompartmentSelectionStrategy, CompartmentSelector, CompartmentSelectorConfiguration } from '@ekkogmbh/apisdk';
import { action, observable } from 'mobx';
import { invalidCoordinatePrefix } from '../../Common/Helper/Validation';

export interface CompartmentSelectorState {
  name: string;
  coordinate: string;
  type: CompartmentSelectionStrategy;
  configuration: CompartmentSelectorConfiguration;
  allFilled: boolean;
}

export class CompartmentSelectorStore {
  @observable
  public editableCompartmentSelector?: CompartmentSelector;

  @observable
  public state: CompartmentSelectorState = {
    name: '',
    coordinate: '',
    type: CompartmentSelectionStrategy.COORDINATE,
    configuration: {},
    allFilled: false,
  };

  @action
  public setState = (newState: Partial<CompartmentSelectorState>, setAllFilled: boolean = true): void => {
    this.state = {
      ...this.state,
      ...newState,
    };

    const isValueHasFields =
      this.state.type === CompartmentSelectionStrategy.VALUE &&
      this.state.configuration.fields &&
      this.state.configuration.fields.length > 0;

    const isCoordinatePrefixValid =
      this.state.type === CompartmentSelectionStrategy.COORDINATE &&
      (this.state.configuration.prefix === undefined ||
        !invalidCoordinatePrefix(this.state.coordinate, this.state.configuration.prefix));

    if (setAllFilled) {
      const allFilled =
        this.state.name !== '' &&
        this.state.coordinate !== '' &&
        this.state.type !== undefined &&
        (isValueHasFields || isCoordinatePrefixValid);
      this.state = {
        ...this.state,
        allFilled,
      };
    }
  };

  public resetStore = (): void => {
    this.setState({
      name: '',
      coordinate: '',
      type: CompartmentSelectionStrategy.COORDINATE,
      configuration: {},
      allFilled: false,
    });

    this.editableCompartmentSelector = undefined;
  };

  public setEditableCompartmentSelector = (compartmentSelector: CompartmentSelector): void => {
    this.editableCompartmentSelector = compartmentSelector;

    if (compartmentSelector) {
      const state = { ...compartmentSelector };
      this.setState(state, true);
    }
  };

  public setConfigurationValue = (key: string, value: string | string[] | Record<string, string | number>): void => {
    if (
      key !== 'batch' &&
      (value === '' ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'object' && Object.values(value).every((v) => v === '')))
    ) {
      this.removeConfigurationValue(key);
      return;
    }

    const newConfig = {
      ...this.state.configuration,
      [key]: value,
    };

    this.setState({ configuration: newConfig });
  };

  public removeConfigurationValue = (key: string): void => {
    const newConfig = {
      ...this.state.configuration,
    };

    delete newConfig[key];

    this.setState({ configuration: newConfig });
  };

  public hasConfiguration = (key: string): boolean => Object.keys(this.state.configuration).includes(key);
}
