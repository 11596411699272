import { Provider } from 'mobx-react';
import React from 'react';
import { Page } from 'src/Common/Components/Page';
import { IntegratedServicesManagementContent } from './Components/IntegratedServicesManagementContent';

const stores = {};

export const IntegratedServicesManagement = (): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={'Integrated Services'} isSearchable={false} isScoped>
      <IntegratedServicesManagementContent />
    </Page>
  </Provider>
);
