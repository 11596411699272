import { CssBaseline, Fade, Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { SearchBarStore } from '../Stores/SearchBarStore';
import { Body } from './Body';
import { NavigationDrawer } from './NavigationDrawer';
import { CoordinateScopeStore } from '../Stores/CoordinateScopeStore';

const styles = (_: Theme) => ({});

interface PageProps extends WithStyles<typeof styles> {
  title: string;
  isSearchable?: boolean;
  isScoped?: boolean;
  searchBarStore?: SearchBarStore;
  coordinateScopeStore?: CoordinateScopeStore;
  children: React.ReactNode;
}

@inject('searchBarStore', 'coordinateScopeStore')
@observer
class PageComponent extends React.Component<PageProps> {
  constructor(props: PageProps) {
    super(props);
    props.searchBarStore!.setSearchable(props.isSearchable!);
    props.coordinateScopeStore!.setScoped(!!props.isScoped);
  }

  public render() {
    const { children, title } = this.props;
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <CssBaseline />
          <NavigationDrawer />
          <Body key={`body-${title}`} title={title}>
            <Fade in={true} timeout={350} style={{ width: '100%' }}>
              <div>{children}</div>
            </Fade>
          </Body>
        </div>
      </React.Fragment>
    );
  }
}

export const Page = withStyles(styles)(PageComponent);
