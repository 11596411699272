import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';
import { EventRuleStore } from '../Stores/EventRuleStore';
import { WithStyles, withStyles } from '@mui/styles';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledFormHeader } from 'src/Common/Components/Forms/StyledFormHeader';
import { StyledTextField } from 'src/Common/Components/Forms/StyledTextField';
import { CoordinateInput } from 'src/Common/Components/CoordinateInput';
import { FormPanelButtons } from 'src/Common/Components/FormPanelButtons';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { CompartmentSelector, CompartmentSelectorIndex, EventRule, EventRuleActionType } from '@ekkogmbh/apisdk';
import { enqueueSnackbar } from 'notistack';
import { CompartmentSelectorPicker } from 'src/CompartmentSelectorManagement/CompartmentSelectorPicker';
import { EventTriggerStore } from '../Stores/EventTriggerStore';
import { EventTriggerForm } from './EventTriggerForm';
import { ListForm } from 'src/Common/Components/Forms/ListForm';

const styles = FormStyles;

interface EventRulePanelStores {
  api: ApiStore;
  eventRuleStore: EventRuleStore;
  eventTriggerStore: EventTriggerStore;
}

interface EventRulePanelState {
  loading: boolean;
}

export interface EventRulePanelProps extends WithStyles<typeof styles> {
  closeHandler: () => void;
  saveHandler: (eventRule: EventRule, overwrite: boolean) => Promise<void>;
}

@inject('api', 'eventRuleStore', 'eventTriggerStore')
@observer
class EventRulePanelComponent extends React.Component<EventRulePanelProps, EventRulePanelState> {
  public state: EventRulePanelState = {
    loading: false,
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): EventRulePanelStores {
    return this.props as EventRulePanelProps & EventRulePanelStores;
  }

  public componentWillUnmount(): void {
    const { eventRuleStore, eventTriggerStore } = this.stores;
    eventRuleStore.resetStore();
    eventTriggerStore.reset();
    cancelFetchPromises(this.fetchPromises);
  }

  public handleReset = async () => {
    const { eventRuleStore, eventTriggerStore } = this.stores;
    const { editableEventRule } = eventRuleStore;
    eventRuleStore.resetStore(editableEventRule);
    eventTriggerStore.reset(editableEventRule?.trigger);
  };

  public handleSave = async () => {
    const { closeHandler, saveHandler } = this.props;
    const { eventTriggerStore, eventRuleStore } = this.stores;

    const { name, coordinate, action } = eventRuleStore.state;
    const trigger = eventTriggerStore.getConsolidatedTrigger();
    const overwrite = eventRuleStore.editableEventRule !== undefined;

    this.setState({ loading: true }, async () => {
      await saveHandler({ name: name!, coordinate: coordinate!, action: action!, trigger }, overwrite);
      closeHandler();
    });
  };

  public actionForm = (): JSX.Element => {
    const { eventRuleStore } = this.stores;
    const { action, coordinate } = eventRuleStore.state;

    // FIXME: add action config properties to apisdk?
    switch (action?.type) {
      case EventRuleActionType.WEBHOOK:
        return (
          <StyledTextField
            label={'URL'}
            value={action.configuration.url}
            onChange={(e) => {
              eventRuleStore.setActionConfigValue('url', e.target.value as string);
            }}
          />
        );
      case EventRuleActionType.MQTT:
        return (
          <StyledTextField
            label={'Topic'}
            value={action.configuration.topic}
            onChange={(e) => {
              eventRuleStore.setActionConfigValue('topic', e.target.value as string);
            }}
          />
        );
      case EventRuleActionType.CLEAR_EVENT_COMPARTMENT:
        return (
          <>
            <StyledTextField
              label={'Clear Value'}
              value={action.configuration.clearValue}
              onChange={(e) => eventRuleStore.setActionConfigValue('clearValue', e.target.value as string)}
              tooltip={'value to be written into compartment fields'}
            />
            <div>
              <Tooltip title={'Defaults to clearing all fields'}>
                <Typography color="textSecondary" variant={'overline'}>
                  Fields
                </Typography>
              </Tooltip>
              <ListForm
                items={
                  action.configuration.fields && action.configuration.fields !== ''
                    ? action.configuration.fields.split(',')
                    : []
                }
                onChange={(items) => eventRuleStore.setActionConfigValue('fields', items.join(','))}
              />
            </div>
          </>
        );
      case EventRuleActionType.EKANBAN_TRIGGER:
        const selectorName = eventRuleStore.state.action?.configuration.compartmentSelectorName ?? '';
        const selectorIndex: CompartmentSelectorIndex | undefined =
          selectorName == ''
            ? undefined
            : {
                name: selectorName,
                coordinate: eventRuleStore.state.action!.configuration.compartmentSelectorCoordinate,
              };
        return (
          <>
            <div>
              <CompartmentSelectorPicker
                coordinate={coordinate ?? ''}
                onChange={(selector: CompartmentSelector | undefined) => {
                  if (selector !== undefined) {
                    eventRuleStore.setActionConfigValue('compartmentSelectorName', selector.name);
                    eventRuleStore.setActionConfigValue('compartmentSelectorCoordinate', selector.coordinate);
                  } else {
                    eventRuleStore.setActionConfigValue('compartmentSelectorName', '');
                    eventRuleStore.setActionConfigValue('compartmentSelectorCoordinate', '');
                  }
                }}
                selected={selectorIndex}
                onError={() => {
                  enqueueSnackbar<'error'>('Could not load compartment selectors.');
                  this.handleReset();
                }}
              />
            </div>
            <StyledTextField
              label={'Input JsonPath'}
              value={action.configuration.inputJsonPath}
              onChange={(e) => {
                eventRuleStore.setActionConfigValue('inputJsonPath', e.target.value as string);
              }}
              tooltip={
                'JsonPath for extracting the Compartment Selector input from the event payload.\n\nExample: $.event.coordinate'
              }
            />
            <StyledTextField
              label={'Trigger Key'}
              value={action.configuration.triggerKey}
              onChange={(e) => {
                eventRuleStore.setActionConfigValue('triggerKey', e.target.value as string);
              }}
              tooltip={
                'Set a matching key on the ekanban state trigger to only initiate transitions from specific states'
              }
            />
          </>
        );
      default:
        return <></>;
    }
  };

  public render() {
    const { loading } = this.state;
    const { eventRuleStore, eventTriggerStore } = this.stores;
    const { name, coordinate, action, allFilled } = eventRuleStore.state;
    const { closeHandler } = this.props;

    const isEditMode = eventRuleStore.editableEventRule !== undefined;

    return (
      <Grid container spacing={2} alignItems={'stretch'}>
        <div style={{ display: loading ? 'block' : 'none' }}>
          <Grid
            item
            xs={12}
            style={{
              height: 496,
              position: 'relative',
            }}
          >
            <div
              style={{
                top: '50%',
                marginTop: -48,
                position: 'absolute',
                width: '100%',
              }}
            >
              <CheckmarkSpinner complete={false} failure={false} />
            </div>
          </Grid>
        </div>

        {!loading && (
          <>
            <Grid container item xs={11} spacing={2}>
              <Grid item xs={4}>
                <Stack direction={'column'} spacing={1}>
                  <StyledFormHeader label={'Identifier'} />
                  <StyledTextField
                    type={'text'}
                    label={'Name'}
                    value={name}
                    disabled={isEditMode}
                    onChange={(e) => eventRuleStore.setState({ name: e.target.value })}
                  />
                  <CoordinateInput
                    value={coordinate}
                    disabled={isEditMode}
                    onChange={(coordinate) => eventRuleStore.setState({ coordinate })}
                    trailingDelimiter={false}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack direction={'column'} spacing={1}>
                  <StyledFormHeader label={'Action'} />
                  <div>
                    <StyledSelectField
                      native
                      onChange={(e) =>
                        eventRuleStore.setState({
                          action: eventRuleStore.getDefaultAction(e.target.value as EventRuleActionType),
                        })
                      }
                      value={action?.type}
                      label={'Type'}
                    >
                      {Object.keys(EventRuleActionType).map((actionTypeKey: string, index: number) => (
                        <option key={index} value={EventRuleActionType[actionTypeKey]}>
                          {EventRuleActionType[actionTypeKey]}
                        </option>
                      ))}
                    </StyledSelectField>
                  </div>
                  {this.actionForm()}
                  <Typography color="textSecondary" variant={'overline'}>
                    Triggers
                  </Typography>
                  <EventTriggerForm
                    coordinate={coordinate!}
                    initialTrigger={eventRuleStore.editableEventRule?.trigger}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormPanelButtons
                cancelHandler={closeHandler}
                saveHandler={this.handleSave}
                resetHandler={this.handleReset}
                isDeleteHidden={true}
                isSaveDisabled={!allFilled || !eventTriggerStore.isSavable()}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  }
}

const StyleWrapped = withStyles(styles)(EventRulePanelComponent);

export const EventRulePanel = StyleWrapped;
