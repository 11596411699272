import { action, observable } from 'mobx';

export class CoordinateScopeStore {
  @observable
  public coordinate: string = '';

  @observable
  public isScoped: boolean = false;

  @action
  public setScoped(isScoped: boolean): void {
    this.isScoped = isScoped;
  }
}
