import { Grid, Hidden } from '@mui/material';
import { GridSize } from '@mui/material/Grid';
import { GridProps } from '@mui/material/Grid/Grid';
import { HiddenProps } from '@mui/material/Hidden';
import React from 'react';

export const spcr = (xs?: GridSize, hideSize?: string | string[], gridProps?: GridProps): React.JSX.Element => {
  const display = { xl: 'block', lg: 'block', md: 'block', sm: 'block', xs: 'block' };

  if (hideSize && Array.isArray(hideSize)) {
    hideSize.forEach((size) => {
      display[size] = 'none';
    });
  } else if (hideSize) {
    display[hideSize] = 'none';
  }

  return (
    <Grid item xs={xs} sx={{ display }} {...gridProps}>
      {}
    </Grid>
  );
};

export const spacer = (xs?: GridSize, gridProps?: GridProps): React.JSX.Element => (
  <Hidden lgDown>
    <Grid item xs={xs} {...gridProps}>
      {}
    </Grid>
  </Hidden>
);

export const spacerCustom = (hiddenProps: HiddenProps, gridProps: { [key: string]: GridSize }): React.JSX.Element => (
  <Hidden {...hiddenProps}>
    <Grid item {...gridProps}>
      {}
    </Grid>
  </Hidden>
);
