import { DeviceIntegrationDescription, EslManagerPrivateRoute, HttpMethod, Technology } from '@ekkogmbh/apisdk';
import { Refresh } from '@mui/icons-material';
import { Checkbox, IconButton, ListItemText, MenuItem, SelectChangeEvent, SelectProps, Tooltip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface TechnologyPickerStores {
  api: ApiStore;
}

interface TechnologyPickerProps extends WithStyles<typeof styles> {
  coordinate?: string;
  selected?: string | string[];
  optional?: boolean;
  multiple?: boolean;
  onChange: (selection: string | string[] | undefined) => void;
  onError?: () => void;
  disabled?: boolean;
  label?: string;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
}

interface TechnologyPickerState {
  loading: boolean;
  failure: boolean;
  technologyNames: string[];
  integrationNames: string[];
  combinedNames: string[];
}

@inject('api')
class TechnologyPickerComponent extends Component<TechnologyPickerProps, TechnologyPickerState> {
  public state: TechnologyPickerState = {
    loading: true,
    failure: false,
    technologyNames: [],
    integrationNames: [],
    combinedNames: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): TechnologyPickerStores {
    return this.props as TechnologyPickerProps & TechnologyPickerStores;
  }

  public async componentDidMount(): Promise<void> {
    await this.fetchTechnologyAndIntegrationNames();
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  private fetchTechnologyAndIntegrationNames = async (): Promise<void> => {
    const { api } = this.stores;
    const { onError, onChange, coordinate, optional, selected, multiple } = this.props;

    const onErrorCallback = () => {
      this.setState({ failure: true, technologyNames: [], integrationNames: [] }, onError);
    };

    const integrationNames: string[] = [];
    const technologyNames: string[] = [];
    const fetchNamesPromises: Promise<void>[] = [];

    try {
      if (coordinate && coordinate !== '') {
        const fetchIntegrationNames = async (): Promise<void> => {
          const integrations = await request<DeviceIntegrationDescription[]>(
            api,
            enqueueSnackbar,
            this.fetchPromises,
            api.getAvailableDeviceIntegrations(coordinate),
            EslManagerPrivateRoute.AVAILABLE_DEVICE_INTEGRATIONS,
            HttpMethod.GET,
            undefined,
            undefined,
            onErrorCallback,
          );

          integrations.forEach((desc) => integrationNames.push(desc.name));
        };

        fetchNamesPromises.push(fetchIntegrationNames());
      }

      const fetchTechnologyNames = async (): Promise<void> => {
        const technologies = await request<Technology[]>(
          api,
          enqueueSnackbar,
          this.fetchPromises,
          api.getTechnologies(),
          EslManagerPrivateRoute.TECHNOLOGIES,
          HttpMethod.GET,
          undefined,
          undefined,
          onErrorCallback,
        );

        technologies.forEach((tech) => technologyNames.push(tech.name));
      };

      fetchNamesPromises.push(fetchTechnologyNames());
      await Promise.all(fetchNamesPromises);
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    const combinedNames = technologyNames.concat(integrationNames);

    if (combinedNames.length > 0) {
      if (!(multiple || optional || selected)) {
        onChange(combinedNames[0]);
      }
    } else {
      onErrorCallback();
    }

    this.setState({ technologyNames, integrationNames, combinedNames, loading: false });
  };

  private getSelectedIndices = (selection?: string | string[]): number | number[] | '' => {
    const { combinedNames } = this.state;

    if (selection instanceof Array) {
      return selection.map((name: string) => combinedNames.indexOf(name));
    }

    return selection ? combinedNames.indexOf(selection) : '';
  };

  private onChange = (event: SelectChangeEvent<unknown>) => {
    const { onChange } = this.props;
    const { combinedNames } = this.state;

    const selection = event.target.value;

    if (selection instanceof Array) {
      const selectedNames = selection.map((value: number) => combinedNames[value]);
      onChange(selectedNames);
    } else {
      const selectedIndex = selection as number;
      onChange(selectedIndex < 0 ? undefined : combinedNames[selection as number]);
    }
  };

  public render() {
    const { coordinate, disabled, multiple, selected, label, optional, forwardedRef } = this.props;
    const { combinedNames, loading, failure } = this.state;

    const entries: React.JSX.Element[] = [];

    if (optional && !multiple) {
      entries.push(
        <MenuItem key={'technology-option-none'} value={-1}>
          <ListItemText primary={'-'} />
        </MenuItem>,
      );
    }

    combinedNames.forEach((name: string, index: number) => {
      entries.push(
        <MenuItem key={`technology-option-${index}-${name}`} value={index}>
          {multiple && <Checkbox checked={(selected as string[]).includes(name)} />}
          <ListItemText primary={name} />
        </MenuItem>,
      );
    });

    const renderValue = (value: SelectProps['value']) => {
      const selectedIndices = value as number[];
      const combinedNamesString = selectedIndices.map((value) => combinedNames[value]).join(', ');
      return <ListItemText primary={combinedNamesString} />;
    };

    return (
      <>
        {loading && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
              borderRadius: 4,
              margin: 8,
              padding: 0,
              width: '100%',
            }}
            ref={forwardedRef}
          >
            <CheckmarkSpinner complete={false} failure={failure} />
          </div>
        )}
        {!loading && (
          <StyledSelectField
            disabled={disabled}
            multiple={multiple}
            onChange={this.onChange}
            value={this.getSelectedIndices(selected)}
            label={label ?? multiple ? 'Technologies' : 'Technology'}
            renderValue={multiple ? renderValue : undefined}
            endAdornment={
              <Tooltip title={'reload technology list'}>
                <span>
                  <IconButton
                    disabled={coordinate === ''}
                    aria-label="refresh"
                    onClick={async () => {
                      await this.fetchTechnologyAndIntegrationNames();
                    }}
                  >
                    <Refresh />
                  </IconButton>
                </span>
              </Tooltip>
            }
          >
            {entries}
          </StyledSelectField>
        )}
      </>
    );
  }
}

const StyleWrapped = withStyles(styles)(TechnologyPickerComponent);

// eslint-disable-next-line react/display-name
export const TechnologyPicker = React.forwardRef<HTMLDivElement, Omit<TechnologyPickerProps, 'classes'>>(
  (props, ref) => <StyleWrapped {...props} forwardedRef={ref} />,
);
