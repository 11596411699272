import React from 'react';
import { TitleProps } from 'src/Routes';
import { ZoneStore } from './Stores/ZoneStore';
import { Provider } from 'mobx-react';
import { Page } from 'src/Common/Components/Page';
import { ZoneManagementContent } from './Components/ZoneManagementContent';
import { NotificationStrategyStore } from './Stores/NotificationStrategyStore';
import { EventTriggerStore } from 'src/EventRuleManagement/Stores/EventTriggerStore';

const zoneStore = new ZoneStore();
const notificationStrategyStore = new NotificationStrategyStore();
const eventTriggerStore = new EventTriggerStore();

const stores = { zoneStore, notificationStrategyStore, eventTriggerStore };

export const ZoneManagement = ({ title }: TitleProps): React.JSX.Element => (
  <Provider {...stores}>
    <Page title={title} isSearchable={true}>
      <ZoneManagementContent />
    </Page>
  </Provider>
);
