import { withStyles, WithStyles } from '@mui/styles';
import { DeviceIntegrationStyles, fadeTimeout } from '../Styles/DeviceIntegrationStyles';
import { IntegratedServiceStatus, ServiceState, ServiceStatusStatistic } from '@ekkogmbh/apisdk';
import { Component } from 'react';
import { Card, CardContent, Chip, Grid, Grow, List, ListItem, Paper, Stack, Tooltip, Typography } from '@mui/material';
import classNames from 'classnames';
import { green, red } from '@mui/material/colors';
import { saturation } from 'src/SystemStatus/Styles/SystemStatusStyles';
import { HelpOutlined } from '@mui/icons-material';
import { StatisticElement } from './StatisticElement';

const styles = DeviceIntegrationStyles;

/** @TODO: this is going to be kind of like a ekanban card where the status can be expanded etc to be used in a stack or grid or whatever I end up cooking up */

export interface ServiceStatusElementProps extends WithStyles<typeof styles> {
  serviceStatus: IntegratedServiceStatus;
}

interface ServiceStatusElementState {}

class ServiceStatusElementComponent extends Component<ServiceStatusElementProps, ServiceStatusElementState> {
  public state: ServiceStatusElementState = {};

  private tooltipWrap(element: JSX.Element, tooltipContent: JSX.Element): JSX.Element {
    return (
      <Tooltip arrow title={tooltipContent}>
        {element}
      </Tooltip>
    );
  }

  public header = () => {
    const { serviceStatus, classes } = this.props;
    const { deviceDescription, nodePath, status, connectionStatusMessage } = serviceStatus;

    const capabilityNames = deviceDescription.capabilities.split(',');
    const capabilityList = (
      <Stack>
        <Typography>{'Capabilities'}</Typography>
        <List dense>
          {capabilityNames.map((c, i) => (
            <ListItem key={i}>
              <Typography>{c}</Typography>
            </ListItem>
          ))}
        </List>
      </Stack>
    );

    const statusChip = (
      <Chip
        label={status.toUpperCase()}
        className={classNames(classes.title, classes.connectionStatus)}
        style={{
          backgroundColor: status === ServiceState.ONLINE ? green[saturation] : red[saturation],
        }}
      />
    );

    return (
      <Stack
        direction={'row'}
        spacing={2}
        sx={{
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Typography color={'textSecondary'}>{nodePath}</Typography>
        <Stack direction={'row'} spacing={0} sx={{ alignItems: 'baseline' }}>
          <Typography fontWeight={'bold'} className={classes.title} color={'textPrimary'}>
            {deviceDescription.name}
          </Typography>
          {this.tooltipWrap(<HelpOutlined fontSize={'small'} />, capabilityList)}
        </Stack>
        {(connectionStatusMessage ?? '') !== ''
          ? this.tooltipWrap(statusChip, <Typography>{connectionStatusMessage}</Typography>)
          : statusChip}
      </Stack>
    );
  };

  public informationElement = (infoKey: string) => {
    const { classes, serviceStatus } = this.props;

    return (
      <Grow in={true} timeout={fadeTimeout}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.cardTitle}>{infoKey}</Typography>
            <Typography className={classes.cardText}>{serviceStatus.information[infoKey]}</Typography>
          </CardContent>
        </Card>
      </Grow>
    );
  };

  public render() {
    const { serviceStatus } = this.props;

    const statisticElements = (serviceStatus.statistics ?? []).map((stat: ServiceStatusStatistic, i) => (
      <StatisticElement key={i} statistic={stat} />
    ));
    const infoElements = Object.keys(serviceStatus.information).map((infoKey) => this.informationElement(infoKey));

    return (
      <Paper>
        <Stack spacing={2} sx={{ margin: 2 }} alignContent={'stretch'}>
          {this.header()}
          <Grid container spacing={2} sx={{ alignItems: 'stretch', justifyContent: 'center' }}>
            {statisticElements.concat(infoElements).map((element, i) => (
              <Grid key={i} item xs={2}>
                {element}
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Paper>
    );
  }
}

const StyleWrapped = withStyles(styles)(ServiceStatusElementComponent);
export const ServiceStatusElement = StyleWrapped;
