import { ApiSdkEvents, TaskCollection } from '@ekkogmbh/apisdk';
import { AppBar, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, Toolbar, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import TableRow from '@mui/material/TableRow';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Person from '@mui/icons-material/Person';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { ChangeEvent } from 'react';
import { ApiStore } from '../Stores/ApiStore';
import { ContentTitleStore } from '../Stores/ContentTitleStore';
import { NavigationStore } from '../Stores/NavigationStore';
import { NotificationStore } from '../Stores/NotificationStore';
import { PaginationControls, PaginationStore } from '../Stores/PaginationStore';
import { NavigationStyles } from '../Styles/NavigationStyles';
import { NavigationButton } from './NavigationButton';
import { NavigationSearchBar } from './NavigationSearchBar';
import { NotificationRow } from './NotificationRow';
import { TablePaginationActions, TablePaginationActionsPropsOverride } from './TablePaginationActions';
import { Link } from 'react-router-dom';
import { NavigationCoordinateSelect } from './NavigationCoordinateSelect';

interface HeaderState {}

interface HeaderProps extends WithStyles<typeof NavigationStyles> {
  api?: ApiStore;
  navigationStore?: NavigationStore;
  notificationStore?: NotificationStore;
  paginationStore?: PaginationStore;
  contentTitleStore?: ContentTitleStore;
  title: string;
  sticky: boolean;
}

const stores = ['api', 'navigationStore', 'notificationStore', 'paginationStore', 'contentTitleStore'];

enum PopperOpen {
  PROFILE = 'profile',
  NOTIFICATIONS = 'notifications',
  NONE = '',
}

@inject(...stores)
@observer
class HeaderComponent extends React.Component<HeaderProps, HeaderState> {
  public state: {
    popperOpen: PopperOpen;
    paginationControls: PaginationControls;
  };
  private notificationsAnchor?: React.ReactNode;
  private profileAnchor?: React.ReactNode;
  private stickyChanged: boolean;

  constructor(props: HeaderProps) {
    super(props);

    this.state = {
      popperOpen: PopperOpen.NONE,
      paginationControls: {
        page: null,
        rowCount: 0,
        rowsPerPage: 0,
      },
    };

    const { api, notificationStore, sticky } = this.props;

    this.stickyChanged = sticky;

    const finishedCollectionListener = (taskCollection: TaskCollection) => {
      // @TODO instead of showing the ID, we will have a link to the taskcollection view
      const msg = `Tasks finished. Id: ${taskCollection.id}`;
      enqueueSnackbar(msg);
      notificationStore!.add(msg);
    };

    const failedCollectionListener = (taskCollection: TaskCollection) => {
      // @TODO instead of showing the ID, we will have a link to the taskcollection view
      const msg = `Tasks failed. Id: ${taskCollection.id}`;
      enqueueSnackbar(msg, { variant: 'error' });
      notificationStore!.add(msg);
    };

    api!.off(ApiSdkEvents.TASK_COLLECTION_FINISHED);
    api!.off(ApiSdkEvents.TASK_COLLECTION_FAILED);

    api!.on(ApiSdkEvents.TASK_COLLECTION_FINISHED, finishedCollectionListener);
    api!.on(ApiSdkEvents.TASK_COLLECTION_FAILED, failedCollectionListener);
  }

  public componentDidMount(): void {
    const { paginationStore } = this.props;
    paginationStore!.off('pagination-controls', this.onPaginationControls);

    paginationStore!.on('pagination-controls', this.onPaginationControls);
  }

  public componentWillUnmount(): void {
    const { paginationStore } = this.props;
    paginationStore!.off('pagination-controls', this.onPaginationControls);
  }

  public onPaginationControls = () => {
    const { paginationStore } = this.props;
    const paginationControls = paginationStore!.getPaginationControls();
    this.setState({ paginationControls });
  };

  // @TODO snapshot?
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public componentDidUpdate(_: Readonly<HeaderProps>, __: Readonly<HeaderState>, ___?: any): void {
    if (this.props.sticky) {
      this.stickyChanged = true;
    }
  }

  public getDrawerOpenState = () => {
    const { navigationStore } = this.props;
    return navigationStore!.open;
  };

  public logout = () => {
    const { api } = this.props;
    api!.logout();
  };

  public openProfile = () => {
    this.setState({ popperOpen: PopperOpen.PROFILE });
  };

  public openNotifications = () => {
    this.setState({ popperOpen: PopperOpen.NOTIFICATIONS });
  };

  public closePopper = () => {
    this.setState({ popperOpen: PopperOpen.NONE });
  };

  public removeNotification = (notification: string) => {
    const { notificationStore } = this.props;

    notificationStore!.remove(notification);
    this.forceUpdate();
  };

  public handleRowChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { paginationControls } = this.state;

    if (!paginationControls.changeRowsPerPage) {
      return;
    }

    paginationControls.changeRowsPerPage(Number(event.target.value));
  };

  public handlePageChange = (_: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
    const { paginationControls } = this.state;

    if (!paginationControls.changePage) {
      return;
    }

    paginationControls.changePage(page);
  };

  public render() {
    const { classes, title, sticky, notificationStore, api, contentTitleStore } = this.props;
    const { popperOpen } = this.state;

    const user = api!.getAuthenticatedUser() || { username: '' };
    const { username } = user;

    const setProfileAnchor = (node: React.ReactNode) => {
      this.profileAnchor = node;
    };

    const navButtons = [
      {
        refFunc: setProfileAnchor,
        click: this.openProfile,
        text: 'Profile',
        icon: Person,
      },
      {
        refFunc: undefined,
        click: this.logout,
        text: 'Logout',
        icon: ExitToApp,
      },
    ];

    const { paginationControls } = this.state;

    const textLabelsPagination = {
      next: 'Next Page',
      previous: 'Previous Page',
      rowsPerPage: 'Rows per page:',
      displayRows: 'of',
    };

    const displayedRows = (param: { from: number; to: number; count: number }) => {
      const { from, to, count } = param;
      return `${from}-${to} ${textLabelsPagination.displayRows} ${count}`;
    };

    const actionsComponent = (props: TablePaginationActionsPropsOverride) =>
      !sticky ? (
        <TablePaginationActions {...props} sticky={sticky} />
      ) : (
        <TablePaginationActions {...props} sticky={sticky} classes={{ button: classes.paginationButtonSticky }} />
      );

    if (!paginationControls.options) {
      paginationControls.options = { rowsPerPageOptions: [0] };
    }

    const { contentTitle } = contentTitleStore!;

    const titleFn = (titleChild: string | React.JSX.Element | undefined, index: number) => (
      <span key={index}>{titleChild}</span>
    );

    const titleChildren = [title, contentTitle].map(titleFn);

    return (
      <div className={classes.appBarWrapper}>
        <AppBar
          position="relative"
          className={classNames(
            classes.appBar,
            this.getDrawerOpenState() && classes.appBarShift,
            sticky && classes.appBarSticky,
            this.stickyChanged && classes.appBarAnimationDuration,
          )}
        >
          <Toolbar className={classes.toolbar}>
            {!sticky && (
              <>
                <Typography component="div" variant="h6" color="primary" noWrap className={classes.title}>
                  {titleChildren}
                </Typography>
                <NavigationCoordinateSelect />
              </>
            )}

            <NavigationSearchBar sticky={sticky} />

            {paginationControls.page !== null && (
              <Table style={{ width: 'auto' }}>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      className={classNames(classes.paginationRoot, sticky && classes.paginationRootSticky)}
                      classes={{
                        toolbar: classNames(classes.paginationToolbar, sticky && classes.paginationToolbarSticky),
                        spacer: classNames(classes.paginationSpacer, sticky && classes.paginationSpacerSticky),
                        selectLabel: classNames(classes.paginationCaption, sticky && classes.paginationCaptionSticky),
                        displayedRows: classNames(classes.paginationCaption, sticky && classes.paginationCaptionSticky),
                        selectRoot: classNames(
                          classes.paginationSelectRoot,
                          sticky && classes.paginationSelectRootSticky,
                        ),
                        select: classNames(classes.paginationSelect, sticky && classes.paginationSelectSticky),
                        selectIcon: classNames(
                          classes.paginationSelectIcon,
                          sticky && classes.paginationSelectIconSticky,
                        ),
                        input: classNames(classes.paginationInput, sticky && classes.paginationInputSticky),
                        menuItem: classNames(classes.paginationMenuItem, sticky && classes.paginationMenuItemSticky),
                        actions: classNames(classes.paginationActions, sticky && classes.paginationActionsSticky),
                      }}
                      ActionsComponent={actionsComponent as React.ReactType<TablePaginationActionsProps>}
                      count={paginationControls.rowCount}
                      rowsPerPage={paginationControls.rowsPerPage}
                      page={paginationControls.page}
                      labelRowsPerPage={textLabelsPagination.rowsPerPage}
                      labelDisplayedRows={displayedRows}
                      backIconButtonProps={{
                        'aria-label': textLabelsPagination.previous,
                      }}
                      nextIconButtonProps={{
                        'aria-label': textLabelsPagination.next,
                      }}
                      rowsPerPageOptions={paginationControls.options.rowsPerPageOptions}
                      onPageChange={this.handlePageChange}
                      onRowsPerPageChange={this.handleRowChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            )}

            {navButtons.map((btn) => (
              <NavigationButton
                key={btn.text}
                onClick={btn.click}
                tooltipText={btn.text}
                icon={btn.icon}
                classes={classes}
                sticky={sticky}
                buttonRefFunc={btn.refFunc}
              />
            ))}

            <Popper
              open={popperOpen === PopperOpen.NOTIFICATIONS}
              anchorEl={this.notificationsAnchor as HTMLElement}
              transition
              disablePortal={sticky}
              style={{ zIndex: 6000 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className={classes.appBarNotifications}>
                    <ClickAwayListener onClickAway={this.closePopper}>
                      <MenuList>
                        {notificationStore!.notifications.map((notification: string, index: number) => (
                          <NotificationRow
                            key={`noti-${index}`}
                            removeHandler={this.removeNotification}
                            notification={notification}
                          />
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>

            <Popper
              open={popperOpen === PopperOpen.PROFILE}
              anchorEl={this.profileAnchor as HTMLElement}
              transition
              disablePortal={sticky}
              style={{ zIndex: 6000 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper className={classes.appBarNotifications}>
                    <ClickAwayListener onClickAway={this.closePopper}>
                      <MenuList>
                        <MenuItem divider={true} disabled={true}>
                          <Typography>
                            Signed in as <span style={{ fontWeight: 700 }}>{username}</span>
                          </Typography>
                        </MenuItem>
                        <MenuItem divider={true} disabled={true} />
                        <MenuItem divider={true}>
                          <Link className={classes.link} to={'/me/'}>
                            <Typography>Your Profile</Typography>
                          </Link>
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const StyleWrapped = withStyles(NavigationStyles)(HeaderComponent);

export const Header = StyleWrapped;
