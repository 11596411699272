import { EslManagerPrivateRoute, EslManagerPublicRouteV1, HttpMethod, Template } from '@ekkogmbh/apisdk';
import { Checkbox, ListItemText, MenuItem, SelectChangeEvent, SelectProps } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { inject } from 'mobx-react';
import { enqueueSnackbar } from 'notistack';
import React, { Component } from 'react';
import { CheckmarkSpinner } from 'src/Common/Components/CheckmarkSpinner';
import { StyledSelectField } from 'src/Common/Components/Forms/StyledSelectField';
import { request } from 'src/Common/Helper/FetchHandler';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { FormStyles } from 'src/Common/Styles/FormStyles';

const styles = FormStyles;

interface TemplatePickerStores {
  api: ApiStore;
}

interface TemplatePickerProps extends WithStyles<typeof styles> {
  coordinate: string;
  linkableOnly?: boolean;
  selected?: Template | Template[] | string | string[];
  optional?: boolean;
  onChangeCallback: (selection: Template | Template[] | undefined) => void;
  onErrorCallback?: () => void;
  multiple?: boolean;
  disabled?: boolean;
  label?: string;
  forwardedRef?: React.ForwardedRef<HTMLDivElement>;
}

interface TemplatePickerState {
  loading: boolean;
  failure: boolean;
  templates: Template[];
  selectedIndices: number[];
}

@inject('api')
class TemplatePickerComponent extends Component<TemplatePickerProps, TemplatePickerState> {
  public state: TemplatePickerState = {
    loading: true,
    failure: false,
    templates: [],
    selectedIndices: [],
  };
  private fetchPromises: CancelableFetchPromises = {};

  get stores(): TemplatePickerStores {
    return this.props as TemplatePickerProps & TemplatePickerStores;
  }

  public async componentDidMount(): Promise<void> {
    await this.fetchTemplates();
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
  }

  public static getDerivedStateFromProps(
    props: Readonly<TemplatePickerProps>,
    state: TemplatePickerState,
  ): Partial<TemplatePickerState> | null {
    const { selected } = props;

    if (selected === undefined || (Array.isArray(selected) && selected.length === 0)) {
      return null;
    }

    const { templates } = state;

    if (templates.length === 0) {
      return null;
    }

    if (typeof selected === 'string') {
      const index = templates.findIndex((template: Template) => template.name === selected);
      return { selectedIndices: index > -1 ? [index] : [] };
    }

    if (Array.isArray(selected)) {
      const indices = selected.map((tpl: string | Template) =>
        templates.findIndex((template: Template) =>
          typeof tpl === 'string' ? template.name === tpl : template === tpl,
        ),
      );
      return { selectedIndices: indices.filter((index: number) => index > -1) };
    }

    return null;
  }

  // "selected" can be a mobx object that contains an array. this function is to convert it into a regular array
  // careful! if it is used inside a render function, updates won't be triggered
  private convertSelected = (
    selected: Template | Template[] | string | string[] | undefined,
  ): Array<Template | string> | Template | Template[] | string | string[] | undefined => {
    if (selected === undefined) {
      return selected;
    }

    if (typeof selected === 'string') {
      return selected;
    }

    const isTemplateObject = (obj: unknown): obj is Template =>
      (obj as Template).hasOwnProperty('name') && (obj as Template).hasOwnProperty('id');

    if (isTemplateObject(selected)) {
      return selected;
    }

    return selected.map((tpl) => tpl);
  };

  private fetchTemplates = async (): Promise<void> => {
    const { api } = this.stores;
    const { coordinate, linkableOnly, onErrorCallback, onChangeCallback, optional, multiple, selected } = this.props;

    // TODO: verify this is correct / enough for all cases
    // When selecting by name, we only want uniquely named templates, which we get in the linkableOnly case.
    // Therefore when given names, we enforce the linkableOnly flag
    const isSelectByName =
      typeof selected === 'string' ||
      (Array.isArray(selected) && selected.length > 0 && typeof selected[0] === 'string');
    const useUniqueNames = linkableOnly || isSelectByName;

    const onError = () => {
      this.setState({ failure: true, templates: [] }, onErrorCallback);
    };

    let templates: Template[] = [];
    try {
      if (useUniqueNames) {
        templates = await request<Template[]>(
          api,
          enqueueSnackbar,
          this.fetchPromises,
          api.getCompartmentTemplates(coordinate),
          EslManagerPublicRouteV1.COMPARTMENT_TEMPLATES,
          HttpMethod.GET,
          undefined,
          undefined,
          onError,
        );
      } else {
        templates = await request<Template[]>(
          api,
          enqueueSnackbar,
          this.fetchPromises,
          api.getAvailableTemplates(coordinate, false),
          EslManagerPrivateRoute.AVAILABLE_TEMPLATES,
          HttpMethod.GET,
          undefined,
          undefined,
          onError,
        );
      }
    } catch (e) {
      cancelFetchPromises(this.fetchPromises);
    }

    if (templates.length > 0) {
      let indices = [0];

      const convSelected = this.convertSelected(selected);

      if (convSelected) {
        if (typeof convSelected === 'string') {
          const idx = templates.findIndex((template: Template) => template.name === convSelected);

          if (idx > -1) {
            indices = [idx];
          }
        } else if (Array.isArray(convSelected) && convSelected.length > 0 && typeof convSelected[0] === 'string') {
          const idx = convSelected.map((tpl: string | Template) =>
            templates.findIndex((template: Template) =>
              typeof tpl === 'string' ? template.name === tpl : template === tpl,
            ),
          );

          if (idx.some((i: number) => i > -1)) {
            indices = idx;
          }
        } else if (Array.isArray(convSelected) && convSelected.length > 0 && typeof convSelected[0] !== 'string') {
          const idx = convSelected.map((tpl: string | Template) =>
            templates.findIndex((template: Template) =>
              typeof tpl === 'string' ? template.name === tpl : template === tpl,
            ),
          );

          if (idx.some((i: number) => i > -1)) {
            indices = idx;
          }
        } else if (!Array.isArray(convSelected)) {
          const idx = templates.findIndex((template: Template) => template.name === convSelected.name);

          if (idx > -1) {
            indices = [idx];
          }
        }
      }

      if (!(multiple || optional)) {
        // auto-select first entry when applicable
        this.setState({ loading: false, templates, selectedIndices: [indices[0]] }, () =>
          onChangeCallback(templates[indices[0]]),
        );
      } else if (
        convSelected === undefined ||
        (Array.isArray(convSelected) && convSelected.length === 0) ||
        convSelected === ''
      ) {
        this.setState({ loading: false, templates });
      } else {
        this.setState({ loading: false, templates, selectedIndices: indices }, () =>
          onChangeCallback(indices.map((idx: number) => templates[idx])),
        );
      }
    } else {
      onError();
    }
  };

  private getValue = (): number[] | number | null => {
    const { multiple } = this.props;
    const { selectedIndices } = this.state;

    return multiple ? selectedIndices : selectedIndices.length > 0 ? selectedIndices[0] : null;
  };

  private onChange = (event: SelectChangeEvent<unknown>) => {
    const { onChangeCallback } = this.props;
    const { templates } = this.state;

    const selection = event.target.value;

    if (Array.isArray(selection)) {
      const selectedIndices = selection as number[];
      this.setState({ selectedIndices }, () => onChangeCallback(selectedIndices.map((idx: number) => templates[idx])));
    } else {
      const selectedIndex = selection as number;

      this.setState({ selectedIndices: [selectedIndex] }, () => onChangeCallback(templates[selectedIndex]));
    }
  };

  public render() {
    const { disabled, multiple, label, optional, forwardedRef } = this.props;
    const { templates, loading, failure, selectedIndices } = this.state;

    const entries: React.JSX.Element[] = [];

    if (optional && !multiple) {
      entries.push(
        <MenuItem key={'template-option-none'} value={-1}>
          <ListItemText primary={'-'} />
        </MenuItem>,
      );
    }

    templates.forEach((template: Template, index: number) => {
      entries.push(
        <MenuItem key={`template-option-${index}-${template.id}`} value={index}>
          {multiple && <Checkbox checked={selectedIndices.includes(index)} />}
          <ListItemText primary={templates[index].name} />
        </MenuItem>,
      );
    });

    const renderValue = (value: SelectProps['value']) => {
      let renderedValue: string;

      if (value === undefined) {
        return undefined;
      }

      if (Array.isArray(value)) {
        renderedValue = (value as number[])
          .map((idx: number) => {
            const template = templates[idx];
            return template ? template.name : '';
          })
          .join(', ');
      } else {
        const template = templates[value as number];
        renderedValue = template ? template.name : '';
      }

      return <ListItemText primary={renderedValue} />;
    };

    return (
      <div ref={forwardedRef}>
        {loading && (
          <div
            style={{
              borderStyle: 'solid',
              borderColor: 'rgba(0, 0, 0, 0.23)',
              borderWidth: 1,
              borderRadius: 4,
              margin: 8,
              padding: 0,
              width: '100%',
            }}
          >
            <CheckmarkSpinner complete={false} failure={failure} />
          </div>
        )}
        {!loading && (
          <StyledSelectField
            disabled={disabled}
            multiple={multiple}
            onChange={this.onChange}
            value={this.getValue() ?? ''}
            label={label ?? multiple ? 'Templates' : 'Template'}
            renderValue={renderValue}
          >
            {entries}
          </StyledSelectField>
        )}
      </div>
    );
  }
}

const StyleWrapped = withStyles(styles)(TemplatePickerComponent);

// eslint-disable-next-line react/display-name
export const TemplatePicker = React.forwardRef<HTMLDivElement, Omit<TemplatePickerProps, 'classes'>>((props, ref) => (
  <StyleWrapped {...props} forwardedRef={ref} />
));
