import { withStyles, WithStyles } from '@mui/styles';
import { DeviceIntegrationStyles } from '../Styles/DeviceIntegrationStyles';
import { RouteComponentProps, withRouter } from 'react-router';
import { ApiStore } from 'src/Common/Stores/ApiStore';
import { SearchContentStore } from 'src/Common/Stores/SearchContentStore';
import { inject, observer } from 'mobx-react';
import { Component } from 'react';
import { request } from 'src/Common/Helper/FetchHandler';
import { EslManagerPrivateRoute, HttpMethod, IntegratedServiceStatus, PaginationResponse } from '@ekkogmbh/apisdk';
import { enqueueSnackbar } from 'notistack';
import { CancelableFetchPromises, cancelFetchPromises } from 'src/Common/Helper/PromiseHelper';
import { Stack } from '@mui/material';
import { ServiceStatusElement } from './ServiceStatusElement';
import { IReactionDisposer, reaction } from 'mobx';
import { LoadingMask } from 'src/Common/Components/LoadingMask';
import { CoordinateScopeStore } from 'src/Common/Stores/CoordinateScopeStore';

const styles = DeviceIntegrationStyles;

const stores = ['api', 'coordinateScopeStore', 'searchContentStore'];

interface IntegratedServicesManagementContentStores {
  api: ApiStore;
  searchContentStore: SearchContentStore;
  coordinateScopeStore: CoordinateScopeStore;
}

interface IntegratedServicesManagementContentProps extends WithStyles<typeof styles>, RouteComponentProps {}

type IntegratedServicesManagementContentPropsWithStores = IntegratedServicesManagementContentProps &
  IntegratedServicesManagementContentStores;

interface IntegratedServicesManagementContentState {
  page: number;
  statuses: IntegratedServiceStatus[];
  loading: boolean;
}

@inject(...stores)
@observer
class IntegratedServicesManagementContentComponent extends Component<
  IntegratedServicesManagementContentProps,
  IntegratedServicesManagementContentState
> {
  private reactionDisposer: IReactionDisposer | undefined = undefined;
  private fetchPromises: CancelableFetchPromises = {};
  public state: IntegratedServicesManagementContentState = { page: 1, statuses: [], loading: false };

  get stores(): IntegratedServicesManagementContentPropsWithStores {
    return this.props as IntegratedServicesManagementContentPropsWithStores;
  }

  public componentDidMount(): void {
    const { coordinateScopeStore } = this.stores;

    // subscribe to navigation coordinate
    this.reactionDisposer = reaction(
      () => coordinateScopeStore.coordinate,
      () => this.fetchStatus(),
    );

    this.fetchStatus();
  }

  public componentWillUnmount(): void {
    cancelFetchPromises(this.fetchPromises);
    if (this.reactionDisposer) {
      this.reactionDisposer();
    }
  }

  public fetchStatus = async (): Promise<void> => {
    const { api, searchContentStore, coordinateScopeStore } = this.stores;
    const { page } = this.state;
    const { coordinate } = coordinateScopeStore;

    this.setState({ loading: true });

    const paginatedStatuses = await request<PaginationResponse<IntegratedServiceStatus>>(
      api,
      enqueueSnackbar,
      this.fetchPromises,
      api.getIntegratedServicesStatus({ page }, coordinate),
      EslManagerPrivateRoute.GET_INTEGRATED_SERVICES_STATUS,
      HttpMethod.GET,
    );

    this.setState({ loading: false, statuses: paginatedStatuses.items ?? [] }, () => searchContentStore.emitRefresh());
  };

  public render() {
    const { statuses, loading } = this.state;

    return (
      <>
        {loading && <LoadingMask />}
        {!loading && (
          <Stack spacing={2} direction={'column'} sx={{ alignContent: 'center', minWidth: 0 }}>
            {statuses.map((s, i) => (
              <ServiceStatusElement key={i} serviceStatus={s} />
            ))}
          </Stack>
        )}
      </>
    );
  }
}

const RouterWrapped = withRouter<
  IntegratedServicesManagementContentProps,
  typeof IntegratedServicesManagementContentComponent
>(IntegratedServicesManagementContentComponent);

const StyleWrapped = withStyles(styles)(RouterWrapped);

export const IntegratedServicesManagementContent = StyleWrapped;
